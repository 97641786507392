import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import * as serviceWorker from './serviceWorker';
import axiosMiddleware from 'redux-axios-middleware';
import { client, options } from './components/Instance';

import * as todoApp from './store/reducers';

import 'antd/dist/antd.css';
import './components/UI/IziToast/style.css';

const store = createStore(
    combineReducers(todoApp), applyMiddleware(axiosMiddleware(client, options)), 
    /* preloadedState, */
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
    <Provider store={store}>
        <Routes />
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();