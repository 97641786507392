import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Icon, Dropdown, Menu, Modal } from 'antd';

const { Column } = Table;

const getColorByStatus = (status) => {
  switch(status){
    case 'Ativo': return 'green';
    case 'Convidado': return 'purple';
    case 'Desativado': return 'red';
    default: return 'blue';
  }
}

class UsersList extends Component {

  componentDidMount = () => this.props.loadUsers();

  onAction = (e, user) => {
    switch(e.key){
      case '0': 
      this.props.showEditPermissions(user);
      break;
      case '1': 
        Modal.confirm({
          title: 'Você realmente deseja desabilitar este usuário?',
          content: 'Você poderá reverter esta ação no futuro.',
          okText: 'Sim',
          okType: 'warning',
          cancelText: 'Cancelar',
          onOk: () => this.props.disableUser(user.id)
        });
      break;
      case '1.change': 
        this.props.activeUser(user.id)
      break;
      case '3': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este usuário?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteUser(user.id)
        });
      break;
    }
  }

  render() {
    const { user, users } = this.props;

    return (  
      <div className="table-clabs">
        <Table 
          rowKey="key"
          dataSource={users} 
          pagination={{ pageSize: 10 }}
          bordered={true}
          rowClassName="row-clabs"
        >
          <Column
            title="Nome"
            dataIndex="name"
            key="name"
            render={(text, record, index) => (
              <span>
                {(record.id == user.id)? <b>{record.name}</b> : record.name} {(record.id == user.id && user.tenant.owner==user.id) && (<Tag color="blue">Proprietário</Tag>)}
              </span>
            )}
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
          />
          <Column
            title="Permissões"
            dataIndex="permissions"
            key="permissions"
            render={permissions => (
              <span>
                {permissions.map(row => <Tag color="purple" style={{marginBottom: `5px`}} key={row}>{row}</Tag>)}
              </span>
            )}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={status => (
                <center>
                  <Tag color={getColorByStatus(status)} key={status}>{status}</Tag>
                </center>
            )}
          />
          {(users.length > 1) && (
          <Column
            dataIndex="actions"
            key="actions"
            render={(text, record, index) => (
              (record.id != user.id) && (
              <center key={index}>
                  <Dropdown overlay={
                    <Menu onClick={(e) => this.onAction(e, record)}>
                      <Menu.Item key="0">
                        <Icon type="form"></Icon> Permissões
                      </Menu.Item>
                      {(record.status != 'Desativado')?
                      <Menu.Item key="1">
                        <Icon type="stop"></Icon> Desabilitar
                      </Menu.Item>
                      :
                      <Menu.Item key="1.change">
                        <Icon type="check"></Icon> Ativar
                      </Menu.Item>
                      }
                      <Menu.Divider />
                      <Menu.Item key="3"><Icon type="delete"></Icon> Excluir</Menu.Item>
                    </Menu>
                  } trigger={['click']}>
                    <a className="ant-dropdown-link" href="#">
                      Ações <Icon type="down" />
                    </a>
                  </Dropdown>
              </center>
              )
            )}
          />
          )}
        </Table>
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    user: state.user,
    users: state.users
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadUsers: (data) => dispatch({
      type: 'LOAD_USERS',
      payload: {
        request:{
          method: 'get',
          url:'/users',
          data: data
        }
      }
    }),
    disableUser: (id) => dispatch({
      type: 'DISABLE_USER',
      payload: {
        request:{
          method: 'patch',
          url:'/user/'+id+'/disable',
        }
      },
      id: id
    }),
    activeUser: (id) => dispatch({
      type: 'ACTIVE_USER',
      payload: {
        request:{
          method: 'patch',
          url:'/user/'+id+'/active',
        }
      },
      id: id
    }),
    deleteUser: (id) => dispatch({
      type: 'DELETE_USER',
      payload: {
        request:{
          method: 'delete',
          url:'/user/'+id,
        }
      },
      id: id
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(UsersList);