import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';

import { Button } from 'antd';

import Layout from '../components/UI/Layout/Layout';
import UsersList from '../components/Users/UsersList';
import UsersModalForm from '../components/Users/UsersModalForm';
import EditPermissions from '../components/Users/UsersEditPermissions';

class ScreensUsers extends Component {
    state = {
        visible: false,
        editPermissions: null
    };

    showModal = () => {
        this.setState({ visible: true });
    }

    handleCancel = () => {
        this.setState({ visible: false });
    }

    render() {
        return (
            <div>
                <Route path="/usuarios" render={() => (
                    <Layout>
                        <div style={{ 
                            margin: `0px -16px`, 
                            padding: '15px', 
                            background: '#fff', 
                            marginBottom: `10px` }}>
                            <h1 style={{fontSize: `20px`}}>Usuários{' '}
                            <Button type="primary" style={{float: `right`}} onClick={this.showModal}>
                                Convidar Usuário
                            </Button>
                            </h1>
                        </div>
                        <UsersList 
                            showEditPermissions={(user) => this.setState({editPermissions: user})}
                        />
                    </Layout>
                )}/>
                <EditPermissions 
                    visible={this.state.editPermissions != null}
                    data={this.state.editPermissions}
                    onCancel={() => this.setState({editPermissions: null})}
                />
                <UsersModalForm 
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                />
            </div>
        );
    }
}

export default ScreensUsers;