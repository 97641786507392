import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Row, Col, Input, Switch, Icon, Tooltip, Button } from 'antd';

class CustomFields extends React.Component {

  handleSubmit = () => {
    const { form } = this.props;
    const context = this;

    form.validateFields((err, values) => {

        if (err) {
            return;
        }

        this.props.saveFields(this.props.data.id, values)
        .then(response => {
            form.resetFields();
            context.props.onClose();
        });
    });

  }

  render() {
    const { data, task, onClose } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
        <Drawer
          title={data && this.props.getCustomer(data.customer_id).name}
          width={320}
          closable={false}
          onClose={onClose}
          visible={data != null}
        >
          {task && (
          <Form layout="vertical">
              <Row gutter={16}>
              {task.custom_fields && Object.keys(task.custom_fields).map(key => 
                  <Col span={24} key={key}>
                      <Form.Item label={task.custom_fields[key][0]}>
                      {getFieldDecorator(key, {
                        initialValue: (data && data.custom_fields != null && data.custom_fields.hasOwnProperty(key)? data.custom_fields[key] : '')
                      })(<Input/>)}
                      </Form.Item>
                  </Col>
              )}
              </Row>
              <Button type="primary" onClick={this.handleSubmit}>Salvar</Button>
          </Form>
          )}
        </Drawer>
    );
  }
}

const mapDispatchProps = dispatch => {
  return{
    saveFields: (record_task_id, data) => dispatch({
      type: 'CALENDAR_TASK_SAVE_FIELDS',
      payload: {
        request:{
          method: 'post',
          url: `/calendar/task_record/${record_task_id}/fields`,
          data
        }
      }
    })
  }
}

const mapStateToProps = state => {
    return{
      getCustomer: (id) => state.customers.filter(item => item.id==id)[0]
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Form.create()(CustomFields));