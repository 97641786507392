import React from 'react';
import CurrencyInput from 'react-currency-input';

const InputCurrency = (props) => (
    <CurrencyInput 
        {...props} 
        className="ant-input"
        decimalSeparator="," 
        thousandSeparator="."
        prefix="R$ "
    />
)

export const currency2Float = (value) => {
    if(typeof value !== 'string') return value;
    if(value === '') return 0;
    let tmp = value.replace(/\D+/g, "");
    return parseFloat(tmp.substr(0,(tmp.length-2))+"."+tmp.substr(-2));
}

export const toBRL = (value) => {
    if(typeof value !== 'number') return value;
    return value.toLocaleString('pt-BR', {minimumFractionDigits: 2});
}

export default InputCurrency;