import React, { Component } from 'react';
import Axios from 'axios';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Input from '../UI/Input';

import logo from './logo.png';

import iziToast from 'izitoast';

class SignUp extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            fullname: '',
            company: '',
            email: '',
            password: ''
        }
    }

    notify = (message) => iziToast.show({
        class: 'danger-toast',
        icon: 'icon-close',
        title: 'Ops,',
        message: message,
        titleColor: '#fff',
        messageColor: '#fff',
        iconColor: "#fff",
        backgroundColor: '#FF7273',
        progressBarColor: '#444F60',
        position: 'topCenter',
        transitionIn: 'fadeInDown',
        close: false,
        zindex: 99999,
        timeout: 15000,
    });

    loading = (status) => this.setState({loading : status});

    handleSubmit = (e) => {
        e.preventDefault();
        const _this = this;
        const { fullname, company, email, password } = this.state;

        _this.loading(true);

        Axios.post(`${process.env.REACT_APP_API}/auth/signup`,{
            fullname,
            email,
            company,
            password
        })
        .then((response) => {
            localStorage.setItem('api_token', response.data.api_token);
            _this.props.history.push('/');
        })
        .catch((error) => {
            _this.notify(error.response.data[Object.keys(error.response.data)[0]][0]);
            _this.loading(false);
        });

    }


    render() {
        const { fullname, company, email, password, loading } = this.state;
        return (
            <div>
                <section className="hero is-fullheight is-light-grey is-bold">
                    <div className="hero-body">
                        <div className="container">

                            <div className="columns is-vcentered">
                                <div className="column is-4 is-offset-4">

                                    <div className="login-form-wrapper">

                                        <div className="animated preFadeInLeft fadeInLeft">
                                            <form onSubmit={this.handleSubmit}>

                                                <div className="flex-card auth-card is-light-raised light-bordered card-overflow">
                                                    <div className="auth-card-header header-secondary">
                                                        <a href="/">
                                                            <img src={logo} alt="ContLabs" />
                                                        </a>
                                                    </div>
                                                    <div className="control-material is-secondary">      
                                                        <Input name="fullname" onChange={(e) => this.setState({fullname: e.target.value})} type="text" required />
                                                        <span className="material-highlight"></span>
                                                        <span className="bar"></span>
                                                        <label>Nome completo</label>
                                                    </div>
                                                    <div className="control-material is-secondary">      
                                                        <Input name="email" onChange={(e) => this.setState({email: e.target.value})} type="text" required />
                                                        <span className="material-highlight"></span>
                                                        <span className="bar"></span>
                                                        <label>Email</label>
                                                    </div>
                                                    <div className="control-material is-secondary">      
                                                        <Input name="company" onChange={(e) => this.setState({company: e.target.value})} type="text" required />
                                                        <span className="material-highlight"></span>
                                                        <span className="bar"></span>
                                                        <label>Nome da empresa</label>
                                                    </div>
                                                    <div className="control-material is-secondary">      
                                                        <Input name="password" onChange={(e) => this.setState({password: e.target.value})} type="password" required />
                                                        <span className="material-highlight"></span>
                                                        <span className="bar"></span>
                                                        <label>Escolha uma senha</label>
                                                    </div>
                                                    <p className="has-text-left mt-30">
                                                        <Link className="no-account is-secondary" to="/login">Já possui uma conta?</Link>
                                                    </p>
                                                    <div className="mt-20">
                                                        <button 
                                                            className={`button button-cta btn-align secondary-btn is-fullwidth btn-outlined is-bold rounded raised no-lh ${(loading) ?'is-loading':''}`}
                                                            disabled={!fullname || !email || !company || !password}>
                                                            Cadastrar-se
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </section>

            </div>
        );
    }
}

export default withRouter(SignUp);