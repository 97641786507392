import React from 'react';
import { Form, Row, Tabs } from 'antd';

import TabDefault from './Tabs/Default';
import TabAddress from './Tabs/Address';
import TabOpening from './Tabs/Opening';

const TabPane = Tabs.TabPane;

class FormCustomer extends React.Component {
    state = {
        tabActive: '1',
        default: {
            id: null
        }
    };

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.error != this.props.error){
            this.setState({tabActive: '1'});       
        }
    }

    render() {
        const { tabActive } = this.state;
        const data = {
            ...this.state.default,
            ...this.props.data
        }
        
        return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Tabs 
                    type="card" 
                    activeKey={tabActive} 
                    onChange={(key) => this.setState({tabActive: key})}>
                    <TabPane tab="Dados" key="1" forceRender={true}>
                        <TabDefault {...this.props} data={data}/>
                    </TabPane>
                    <TabPane tab="Endereço" key="2" forceRender={true}>
                        <TabAddress {...this.props} data={data}/>
                    </TabPane>
                    <TabPane tab="Abertura" key="3" forceRender={true}>
                        <TabOpening {...this.props} data={data}/>
                    </TabPane>
                </Tabs>
            </Row>
        </Form>
        );
    }
}

export default FormCustomer;