import React, { Component } from 'react';

import Layout from '../components/UI/Layout/Layout';
import CerticatesList from '../components/Certificates/CerticatesList';
import CertificatesForm from '../components/Certificates/CertificatesForm';

class ScreensCertificates extends Component {

    state = {
        visible: false,
        edit: null
    };

    showModal = () => {
        this.setState({ visible: true });
    }

    handleCancel = () => {
        this.setState({ visible: false, edit: null });
    }

    render() {
        return (
            <div>
                <Layout> 
                    <CerticatesList
                        showEdit={(customer) => this.setState({edit: customer})}
                        showModal={this.showModal}
                    />
                    <CertificatesForm 
                        visible={this.state.visible || this.state.edit}
                        onClose={this.handleCancel}
                        data={this.state.edit}
                    />
                </Layout>
            </div>
        );
    }
}

export default ScreensCertificates;