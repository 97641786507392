import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Switch, Divider, Icon } from 'antd';
import uid from 'uid';
import moment from 'moment';

import { department } from './Utils';

import { DatePicker } from '../UI/DatePicker/Locale';

const { Option } = Select;

class DrawerForm extends React.Component {

  default = {
    id: null,
    name: null,
    department: null,
    frequency: null,
    repeat_annually: null,
    description: null,
    custom_fields: [],
    first_date: null,
    second_date: null,
    third_date: null,
    fourth_date: null
  }

  state = {
    frequency: null,
    fields: [],
    default: this.default
  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.data !== null && nextProps.data.hasOwnProperty('custom_fields')){

      let fields = [];
  
      if(nextProps.data.custom_fields !== null){
        Object.keys(nextProps.data.custom_fields).map((row) => {
          fields.push({
            id: row, 
            value: nextProps.data.custom_fields[row][0], 
            type: nextProps.data.custom_fields[row][1], 
            name: ''
          });
        });
      }

      this.setState({
        fields: fields, 
        frequency: nextProps.data.frequency
      });
    }
  }

  onCreate = () => {
    const { form } = this.props;
    const context = this;

    form.validateFields((err, values) => {

        if (err) {
            return;
        }

        this.props.createTask(values)
        .then(response => {
            form.resetFields();
            context.props.onClose();
        });
    });
  }

  onSave = () => {
    const { form, data } = this.props;
    const context = this;

    form.validateFields((err, values) => {

        if (err) {
            return;
        }

        this.props.saveTask(data.id, values)
        .then(response => {
            form.resetFields();
            context.props.onClose();
        });
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { frequency, fields } = this.state;
    const data = {
      ...this.state.default,
      ...this.props.data
    }
    
    const ruleDatePicker = { rules: [{ required: true, message: 'Por favor escolha uma data' }] };

    return (
      <div>
        <Drawer
          title={data.id ? 'Editar Tarefa':'Criar Nova Tarefa'}
          width={720}
          placement="right"
          onClose={this.props.onClose}
          maskClosable={false}
          visible={this.props.visible}
          destroyOnClose={true}
          style={{
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: 53,
          }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Tarefa">
                  {getFieldDecorator('name', {
                    initialValue: data.name,
                    rules: [{ required: true, message: 'Por favor informe o nome da tarefa!' }],
                  })(<Input placeholder="Insira o nome da tarefa" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Departamento">
                  {getFieldDecorator('department', data.id?{
                    initialValue: data.department,
                    rules: [{ required: true, message: 'Por favor selecione um departamento!' }],
                  }:{rules: [{ required: true, message: 'Por favor selecione um departamento!' }]})(
                    <Select placeholder="Selecione um departamento">
                      <Option value="1">{department(1)}</Option>
                      <Option value="2">{department(2)}</Option>
                      <Option value="3">{department(3)}</Option>
                      <Option value="4">{department(4)}</Option>
                      <Option value="5">{department(5)}</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Periodicidade">
                  {getFieldDecorator('frequency', data.id?{
                    initialValue: data.frequency,
                    rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                  }:{rules: [{ required: true, message: 'Por favor selecione uma opção!' }]})(
                    <Select 
                      placeholder="Defina a periodicidade" 
                      onChange={(value) => this.setState({frequency: value})}
                      disabled={(data.id !== null)}>
                      <Option value="0">Não se repete</Option>
                      <Option value="1">Mensal</Option>
                      <Option value="2">Trimestral</Option>
                      <Option value="3">Semestral</Option>
                      <Option value="4">Anual</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {(frequency != 0) && (
              <Col span={12}>
                <Form.Item label="Repetir anualmente">
                  {getFieldDecorator('repeat_annually')(
                    <Switch 
                      defaultChecked={(data.id==data.repeat_annually)}
                      disabled={(data.id !== null)}
                    />
                  )}
                </Form.Item>
              </Col>
              )}
              {(frequency == 0) && (
              <Col span={12}>
                <Form.Item label={`Vencimento`}>
                  {getFieldDecorator('first_date', data.first_date ? {
                    initialValue: moment(data.first_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              )}
            </Row>
            {(frequency == 1 || frequency == 4) && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={`1º Vencimento`}>
                  {getFieldDecorator('first_date', data.first_date ? {
                    initialValue: moment(data.first_date),
                    ...ruleDatePicker
                    }: ruleDatePicker
                  )(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            )}

            {(frequency == 2) && (
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="1º Vencimento">
                  {getFieldDecorator('first_date', data.first_date ? {
                    initialValue: moment(data.first_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="2º Vencimento">
                  {getFieldDecorator('second_date', data.second_date ? {
                    initialValue: moment(data.second_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="3º Vencimento">
                  {getFieldDecorator('third_date', data.third_date ? {
                    initialValue: moment(data.third_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="4º Vencimento">
                  {getFieldDecorator('fourth_date', data.fourth_date ? {
                    initialValue: moment(data.fourth_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              </Row>
            )}

            {(frequency == 3) && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="1º Vencimento">
                  {getFieldDecorator('first_date', data.first_date ? {
                    initialValue: moment(data.first_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="2º Vencimento">
                  {getFieldDecorator('second_date', data.second_date ? {
                    initialValue: moment(data.second_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            )}

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Descrição">
                {getFieldDecorator('description', {initialValue: data.description})(
                    <Input.TextArea rows={4} placeholder="Descreva detalhes sobre esta tarefa" />
                )}
                </Form.Item>
              </Col>
            </Row>

            <Divider>Campos adicionais</Divider>
            {fields.map((row, index) => 
            <Row gutter={16} key={row.id}>
              <Col span={12}>
                <Form.Item label={`Campo ${index+1}`}>
                  {getFieldDecorator(`fields[${row.id}][0]`, {
                    initialValue: row.value,
                    rules: [{ required: true, message: 'Por favor informe o nome do campo!' }],
                  })(<Input placeholder="Insira o nome do campo" 
                  onChange={
                    (e) => this.setState({
                      fields: [...fields.map((item) => {
                        if(item.id != row.id){
                          return item;
                        }else{
                          return {
                            ...item,
                            value: e.target.value
                          }
                        }
                      })]
                  })}
                  />)}
                </Form.Item>
              </Col>
              <Col span={8}>
              <Form.Item label="Tipo">
                  {getFieldDecorator(`fields[${row.id}][1]`, {
                    initialValue: row.type,
                    rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                  })(
                    <Select placeholder="Tipo do campo">
                      <Option value="text">Texto</Option>
                      <Option value="money">Dinheiro</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={4} className="mt-30">
                <Button type="danger" icon="delete" onClick={
                  () => this.setState({fields: [...fields.filter((f, fIndex) => fIndex != index)] })}/>
              </Col>
            </Row>
            )}
            <Row gutter={16}>
              <Col span={24} className="mb-30">
                <Button onClick={() => this.setState({
                  fields: [
                    ...fields,
                    {
                      name: '',
                      type: 'text',
                      id: uid(10)
                    }
                  ]
                })}>
                  <Icon type="plus" /> Adicionar novo campo
                </Button>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
          >
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
            >
              Cancelar
            </Button>
            {(data.id)?
            <Button onClick={this.onSave} type="primary">Salvar</Button>
            : 
            <Button onClick={this.onCreate} type="primary">Cadastrar</Button>
            }
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapDispatchProps = dispatch => {
  return{
    createTask: (data) => dispatch({
      type: 'CREATE_TASK',
      payload: {
        request:{
          method: 'post',
          url:'/tasks',
          data: data
        }
      }
    }),
    saveTask: (id, data) => dispatch({
      type: 'SAVE_TASK',
      payload: {
        request:{
          method: 'post',
          url:`/tasks/${id}`,
          data: data
        }
      }
    })
  }
}

export default connect(null, mapDispatchProps)(Form.create()(DrawerForm));