const customers = (state = null, action) => {
    switch (action.type) {
        case 'LOAD_CUSTOMERS_SUCCESS':
        return action.payload.data;

        case 'REGISTER_CUSTOMER_SUCCESS':
        return [
            ...state,
            {...action.payload.data.data}
        ];

        case 'DELETE_CUSTOMER_SUCCESS':
        return [
            ...state.filter(item => item.id != action.meta.previousAction.id),
        ];

        case 'SAVE_CUSTOMER_SUCCESS':
        return [
            ...state.map(item => item.id != action.payload.data.data.id? item : action.payload.data.data),
        ];

        /*
        ** Dashtask
        */
        case 'REGISTER_DASHTASK_ITEM_SUCCESS':
        if(parseInt(action.payload.data.data.category) == 1){
            return [
                ...state,
                {...action.payload.data.data.custom_fields}
            ];
        }else{
            return state;
        }

        case 'UPDATE_DASHTASK_ITEM_SUCCESS':
        if(parseInt(action.payload.data.data.category) == 1){
            return [
                ...state.map(item => item.id != action.payload.data.data.customer_id? item : action.payload.data.data.custom_fields),
            ];
        }else{
            return state
        }
        
        default:
        return state
    }
}

export default customers;