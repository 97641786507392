import React, { Component } from 'react';

import { DatePicker, Button, Icon } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';

import Layout from '../components/UI/Layout/Layout';
import Tasks from '../components/CalendarTask/Tasks';
import Grid from '../components/CalendarTask/Grid';
import List from '../components/CalendarTask/List/List';
import { department } from '../components/RegisterTasks/Utils';

import locale from '../components/UI/DatePicker/Locale';

const { MonthPicker } = DatePicker;

class ScreensCalendarTask extends Component {

    state = {
        date: moment().format('MM-YYYY'),
        grid: null,
        list: null,
        listFilter: null
    }

    openGrid = (data) => {
        this.setState({ grid: data });
    }

    closeGrid = () => {
        this.setState({ grid: null });
    }

    openList = (index) => {
        this.setState({ list: index});
    }

    closeList = () => {
        this.setState({ list: null });
    }

    render() {
        const { date, grid, list } = this.state;

        return (
            <Layout>
                <div style={{ 
                    margin: `0px -16px`, 
                    padding: '15px', 
                    background: '#fff', 
                    marginBottom: `10px` }}>
                    <h1 style={{fontSize: `20px`}}>Calendário tarefas > {department(this.props.match.params.id)}{' '}
                    <MonthPicker 
                        style={{float: `right`}}
                        defaultValue={moment(date, 'MM/YYYY')} 
                        format={'MM/YYYY'}
                        locale={locale}
                        onChange={(value) => this.setState({date: value.format('MM-YYYY')})}
                        />
                    </h1>
                </div>
                <Tasks 
                    {...this.props}
                    date={date}
                    openGrid={this.openGrid}
                    openList={this.openList}
                />
                <Grid 
                    {...this.props}
                    visible={(grid != null)}
                    onClose={this.closeGrid}
                    data={grid}
                    date={date}
                />
                <List 
                    visible={(list != null)}
                    onClose={this.closeList}
                    id={list}
                />
            </Layout>
        );
    }
}

export default ScreensCalendarTask;