import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, Switch, List, Tag, Input, Row } from 'antd';

const Search = Input.Search;

class Customers extends React.Component {

    state = {
        search: ''
    }

    componentDidMount = () => {
        this.props.loadCustomers();
    }

    render() {

        const { task } = this.props;
        const { customers } = this.props;

        const { search } = this.state;

        let filteredCustomers = (customers !== null)? customers.filter((str) => {

        if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
            return true;
        }

        if(str.email !== null && str.email.toLowerCase().indexOf(search.toLowerCase()) >= 0){
            return true;
        }

        if(str.identification_number !== null && str.identification_number.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0){
            return true;
        }

        if(str.cnpj_formated !== null && str.cnpj_formated.toLowerCase().indexOf(search.toLowerCase()) >= 0){
            return true;
        }

        if(str.cod !== null && str.cod.toLowerCase().indexOf(search.toLowerCase()) >= 0){
            return true;
        }

        return false;
        }): [];


        return (
        <div>
            {task && (
            <Drawer
                title={`${task.name} / Clientes`}
                width={720}
                placement="right"
                onClose={this.props.onClose}
                maskClosable={true}
                visible={this.props.visible}
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
            <Search 
              placeholder="Pesquisar clientes" 
              onChange={e => this.setState({search: e.target.value})}
              className="mb-10"
            />
            <Row className="mb-50">
                <List
                    dataSource={filteredCustomers}
                    renderItem={item => (
                        <List.Item>
                            <Switch 
                                onChange={(value) => this.props.addCustomer(task.id, {customer_id: item.id, checked: value})} 
                                defaultChecked={(item.tasks.indexOf(task.id) !== -1)}
                            />
                            <div style={{marginLeft: `10px`}}>
                                {item.cod !==''?item.cod+' - ':''} {item.name} {item.type_taxation && (<Tag color="purple">{item.type_taxation}</Tag>)}
                            </div>
                        </List.Item>)}
                />
            </Row>
            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
            >
                <Button
                style={{
                    marginRight: 8,
                }}
                onClick={this.props.onClose}
                >
                Fechar
                </Button>
            </div>
            </Drawer>
            )}
        </div>
        );
    }
}

const mapStateToProps = state => {
    return{
        customers: state.customers
    }
}

const mapDispatchProps = dispatch => {
  return{
    loadCustomers: (data) => dispatch({
      type: 'LOAD_CUSTOMERS',
      payload: {
        request:{
          method: 'get',
          url:'/customers',
          data: data
        }
      }
    }),
    addCustomer: (id, data) => dispatch({
        type: 'TASK_ADD_CUSTOMER',
        payload: {
          request:{
            method: 'POST',
            url:`/task/${id}/add`,
            data: data
          }
        }
      })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(Customers);