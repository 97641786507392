import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Icon, Dropdown, Menu, Modal, Button, Input } from 'antd';

import { toBRL } from '../UI/InputCurrency';

const { Column } = Table;

const Search = Input.Search;

class CustomersList extends Component {
  state = {
    search: ''
  }

  // componentDidMount = () => this.props.loadCustomers();

  onAction = (e, customer) => {
    switch(e.key){
      case 'details': 
      this.props.showDetails(customer);
      break;
      case 'edit': 
      this.props.showEdit(customer);
      break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este cliente?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteCustomer(customer.id)
        });
      break;
    }
  }

  render() {
    const { customers } = this.props;
    const { search } = this.state;

    let filteredCustomers = (customers !== null)? customers.filter((str) => {

      if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.email !== null && str.email.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.identification_number !== null && str.identification_number.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.cnpj_formated !== null && str.cnpj_formated.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.cod !== null && str.cod.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      return false;
    }): [];

    return (  
      <div>
        <div style={{ 
          margin: `0px -16px`, 
          padding: '15px', 
          background: '#fff', 
          marginBottom: `10px` }}>
          <h1 style={{fontSize: `20px`}}>Clientes{' '}
            <Search 
              placeholder="Pesquisar clientes" 
              onChange={e => this.setState({search: e.target.value})}
              style={{width: `30%`}}
            />
            <Button type="primary" style={{float: `right`}} onClick={this.props.showModal}>
                Cadastrar Cliente
            </Button>
          </h1>
        </div>
        <div className="table-clabs">
          <Table 
            rowKey="id"
            dataSource={filteredCustomers} 
            pagination={{ pageSize: 10 }}
            bordered={true}
            rowClassName="row-clabs"
            scroll={{ x: 5300, y: `${window.innerHeight-175}px`}}
          >
              <Column
                  title="Cod"
                  dataIndex="cod"
                  key="cod"
                  fixed="left"
                  width={100}
              />
              <Column
                  title="Empresa"
                  dataIndex="name"
                  key="name"
                  fixed="left"
                  width={400}
              />
              <Column
                  title="Email"
                  dataIndex="email"
                  key="email"
                  width={250}
              />
              <Column
                  title="CNPJ"
                  dataIndex="cnpj_formated"
                  key="cnpj_formated"
                  width={200}
              />
              <Column
                  title="Telefone"
                  dataIndex="phone_formated"
                  key="phone_formated"
                  width={200}
              />
              <Column
                  title="Tipo"
                  dataIndex="type_taxation"
                  key="type_taxation"
                  width={200}
              />
              <Column
                  title="Aquisição"
                  dataIndex="acquisition"
                  key="acquisition"
                  width={200}
              />
              <Column
                  title="Aquisição (Obs)"
                  dataIndex="indication"
                  key="indication"
                  width={200}
              />
              <Column
                  title="CEP"
                  dataIndex="postal_code_formated"
                  key="postal_code_formated"
                  width={200}
              />
              <Column
                  title="Rua"
                  dataIndex="street"
                  key="street"
                  width={400}
              />
              <Column
                  title="Nº"
                  dataIndex="street_number"
                  key="street_number"
                  width={200}
              />
              <Column
                  title="Bairro"
                  dataIndex="neighborhood"
                  key="neighborhood"
                  width={200}
              />
              <Column
                  title="Complemento"
                  dataIndex="complement"
                  key="complement"
                  width={200}
              />
              <Column
                  title="Cidade"
                  dataIndex="city"
                  key="city"
                  width={200}
              />
              <Column
                  title="Estado"
                  dataIndex="state"
                  key="state"
                  width={200}
              />
              <Column
                  title="Faturamento"
                  dataIndex="billing"
                  key="billing"
                  width={200}
                  render={value => toBRL(value)}
              />
              <Column
                  title="NFs Entrada"
                  dataIndex="nfs_input"
                  key="nfs_input"
                  width={200}
              />
              <Column
                  title="NFs Saida"
                  dataIndex="nfs_output"
                  key="nfs_output"
                  width={200}
              />
              <Column
                  title="Lanç. Contábeis"
                  dataIndex="accounting_postings"
                  key="accounting_postings"
                  width={200}
              />
              <Column
                  title="Conciliação"
                  dataIndex="conciliation"
                  key="conciliation"
                  width={200}
                  render={(text, record, index) => record.conciliation? 'Sim':''}
              />
              <Column
                  title="Funcionários"
                  dataIndex="n_staffs"
                  key="n_staffs"
                  width={200}
              />
              <Column
                  title="Tipo Funcionários"
                  dataIndex="type_staff"
                  key="type_staff"
                  width={200}
              />
              <Column
                  title="Honorário de Abertura"
                  dataIndex="first_honorary"
                  key="first_honorary"
                  width={200}
                  render={value => toBRL(value)}
              />
              <Column
                  title="Mensalidade"
                  dataIndex="honorary"
                  key="honorary"
                  width={200}
                  render={value => toBRL(value)}
              />
              <Column
                  dataIndex="actions"
                  key="actions"
                  fixed="right"
                  width={150}
                  render={(text, record, index) => (
                  <center key={index}>
                      <Dropdown overlay={
                          <Menu onClick={(e) => this.onAction(e, record)}>
                          {/* <Menu.Item key="details">
                              <Icon type="pic-right"></Icon> Detalhes
                          </Menu.Item> */}
                          <Menu.Item key="edit">
                              <Icon type="edit"></Icon> Editar
                          </Menu.Item>
                          <Menu.Divider />
                          <Menu.Item key="delete"><Icon type="delete"></Icon> Excluir</Menu.Item>
                          </Menu>
                      } trigger={['click']}>
                          <a className="ant-dropdown-link" href="#">
                          Ações <Icon type="down" />
                          </a>
                      </Dropdown>
                  </center>
              )}
              />
          </Table>
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    customers: state.customers
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadCustomers: (data) => dispatch({
      type: 'LOAD_CUSTOMERS',
      payload: {
        request:{
          method: 'get',
          url:'/customers',
          data: data
        }
      }
    }),
    deleteCustomer: (id) => dispatch({
      type: 'DELETE_CUSTOMER',
      payload: {
        request:{
          method: 'delete',
          url:'/customer/'+id,
        }
      },
      id: id
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(CustomersList);