import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from '../components/UI/Layout/Layout';

import { Menu, Row, Col } from 'antd';

import SettingsBasicForm from '../components/Account/SettingsBasicForm';
import SettingsCompanyForm from '../components/Account/SettingsCompanyForm';
import ChangePasswordForm from '../components/Account/ChangePasswordForm';

const { SubMenu } = Menu;

const _title = {
    fontSize: `20px`, 
    marginBottom: `15px`
};

class ScreensAccount extends Component {
    render() {
        const { user } = this.props;

        return (
            <Layout>
                <Row style={{
                    backgroundColor: `#fff`, 
                    padding: `15px 15px 15px 0px`,
                    marginTop: `20px`
                    }}>
                    <Col span={6}>
                        <Menu
                            defaultSelectedKeys={[this.props.location.pathname]}
                            mode={'inline'}
                            theme={'light'}>
                            <Menu.Item key="/conta">
                                <Link to="/conta">Configurações Básicas</Link>
                            </Menu.Item>
                            {user.id == user.tenant.owner && (
                            <Menu.Item key="/conta/empresa">
                                <Link to="/conta/empresa">Configurações da Empresa</Link>
                            </Menu.Item>
                            )}
                            <Menu.Item key="/conta/senha">
                                <Link to="/conta/senha">Alterar senha</Link>
                            </Menu.Item>
                        </Menu>
                    </Col>
                    <Col span={18} style={{paddingLeft: `25px`}}>
                        <Route path="/conta" exact render={() => (
                            <Row>
                                <Col offset={4}>
                                    <h1 style={{..._title}}>Configurações Básicas</h1>
                                </Col>
                                <Col>
                                    <SettingsBasicForm/>
                                </Col>
                            </Row>
                        )}/>
                        {user.id == user.tenant.owner && (
                        <Route path="/conta/empresa" exact render={() => (
                            <Row>
                                <Col offset={4}>
                                    <h1 style={{..._title}}>Configurações da Empresa</h1>
                                </Col>
                                <Col>
                                    <SettingsCompanyForm/>
                                </Col>
                            </Row>
                        )}/>
                        )}
                        <Route path="/conta/senha" exact render={() => (
                            <Row>
                                <Col offset={4}>
                                    <h1 style={{..._title}}>Alterar senha</h1>
                                </Col>
                                <Col>
                                    <ChangePasswordForm/>
                                </Col>
                            </Row>
                        )}/>
                    </Col>
                </Row>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
  return{
      user: state.user
  }
}

export default connect(mapStateToProps)(ScreensAccount);