export const department = key => {
    switch(parseInt(key)){
        case 1: return 'Legalização';
        case 2: return 'Administrativo';
        case 3: return 'Contábil';
        case 4: return 'Fiscal';
        case 5: return 'Pessoal';
    }
}

export const frequency = key => {
    switch(parseInt(key)){
        case 0: return 'Não se repete';
        case 1: return 'Mensal';
        case 2: return 'Trimestral';
        case 3: return 'Semestral';
        case 4: return 'Anual';
    }
}