import React, { Component } from 'react';
import { Button, Icon, Menu, Dropdown } from 'antd';

import Layout from '../components/UI/Layout/Layout';
import Dashboard from '../components/OpeningClosing/Dashboard';
import Drawer from '../components/OpeningClosing/Drawer';

class ScreensOpeningClosing extends Component {

    state = {
        drawer: null,
        data: null
    };

    openDrawer = (key) => this.setState({drawer: parseInt(key), data: null});

    closeDrawer = () => this.setState({drawer: null, data: null});

    render() {
        const { drawer, data } = this.state;

        return (
            <div>
                <Layout> 
                    <div style={{ 
                        margin: `0px -16px`, 
                        padding: '15px', 
                        background: '#fff', 
                        marginBottom: `10px` }}>
                        <h1 style={{fontSize: `20px`}}>Auditoria <small>| Abertura, Alteração, Encerramento e Tarefas esporádicas</small>{' '}
                        <Dropdown overlay={
                            <Menu onClick={(event) => this.openDrawer(event.key)}>
                                <Menu.Item key="1">Abertura de Empresa</Menu.Item>
                                <Menu.Item key="2">Alteração Contratual</Menu.Item>
                                <Menu.Item key="3">Encerramento de Empresa</Menu.Item>
                                <Menu.Item key="4">Tarefa</Menu.Item>
                            </Menu>
                        }>
                            <Button type="primary" style={{float: `right`}}>
                                Novo <Icon type="down" />
                            </Button>
                        </Dropdown>
                        </h1>
                    </div>
                    <Dashboard 
                        showDrawer={(id, data) => this.setState({
                            drawer: parseInt(id), 
                            data: {
                                ...data.custom_fields,
                                ...data
                            }
                        })}
                    />
                    <Drawer 
                        category={drawer}
                        data={data}
                        onClose={this.closeDrawer}
                    />
                </Layout>
            </div>
        );
    }
}

export default ScreensOpeningClosing;