import React from 'react';
import { Drawer, Form, Button, Col, Row, Divider } from 'antd';

class CustomersDetails extends React.Component {

    render() {
        const { customer } = this.props;
        
        return (
        <div>
            {customer && (
            <Drawer
                title={customer.name}
                width={600}
                placement="right"
                onClose={this.props.onClose}
                maskClosable={true}
                visible={this.props.visible}
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
            <Form layout="vertical">
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item label="Código">
                            {customer.cod}
                        </Form.Item>
                    </Col>
                    <Col span={20}>
                        <Form.Item label="Nome da Empresa">
                            {customer.name}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="CNPJ">
                            {customer.identification_number}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Tipo">
                            {customer.type_taxation}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Email">
                           {customer.email}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Telefone">
                            {customer.phone}
                        </Form.Item>
                    </Col>
                    <Divider>Endereço</Divider>
                    <Col span={6}>
                        <Form.Item label="CEP">
                            {customer.postal_code}
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item label="Rua">
                           {customer.street}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Nº">
                            {customer.street_number}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Bairro">
                            {customer.neighborhood}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Complemento">
                            {customer.complement}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Cidade">
                            {customer.city}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Estado">
                            {customer.state}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
            >
                <Button
                style={{
                    marginRight: 8,
                }}
                onClick={this.props.onClose}
                >
                Fechar
                </Button>
            </div>
            </Drawer>
            )}
        </div>
        );
    }
}


  export default Form.create()(CustomersDetails);