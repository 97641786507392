import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Button, Icon, Modal } from 'antd';

import { DrawerFooter } from '../UI/Drawer';

import FormChange from './Form/FormChange';
import FormClose from './Form/FormClose';
import FormTask from './Form/FormTask';
import FormCustomer from '../Customers/Form/FormCustomer';

const getNameDrawer = (category) => {
  switch(category){
    case 1: return 'Abertura de Empresa';
    case 2: return 'Alteração Contratual';
    case 3: return 'Encerramento de Empresa';
    case 4: return 'Tarefa esporádica';

    default: return '';
  }
}

class DrawerForm extends React.Component {
    state = {
        error: null
    }

    onCreate = () => {
        const { form, category } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.registerItem({
                ...values,
                category
            })
            .then(response => {
                form.resetFields();
                context.props.onClose();
            })
            .catch(data => context.setState({error: data.error}));
           
        });
        
    }

    onSave = () => {
        const { form, category } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.saveItem(this.props.data.id, {
                ...values,
                category
            })
            .then(response => {
                form.resetFields();
                context.props.onClose();
            });
           
        });
        
    }

    onDelete = () => {
        const { id } = this.props.data;
        Modal.confirm({
            title: 'Você realmente deseja exluir?',
            content: 'Você não poderá reverter esta ação no futuro.',
            okText: 'Sim',
            okType: 'warning',
            cancelText: 'Cancelar',
            onOk: () => {
                this.props.deleteItem(id);
                this.props.onClose();
            }
        });
    }

    render() {
      const { category } = this.props;
      const { error } = this.state;
      const defaultData = {
        id: null
      }
      const data = {
        ...defaultData,
        ...this.props.data
      }

      return (
      <div>
          <Drawer
              title={getNameDrawer(category)}
              width={600}
              placement="right"
              onClose={this.props.onClose}
              maskClosable={false}
              visible={(category !== null)}
              style={{
                  height: 'calc(100% - 55px)',
                  overflow: 'auto',
                  paddingBottom: 53,
              }}
              destroyOnClose={true}
          >
            {category === 1 && (<FormCustomer {...this.props} dashboard={true} error={error}/>)}
            {category === 2 && (<FormChange {...this.props}/>)}
            {category === 3 && (<FormClose {...this.props}/>)}
            {category === 4 && (<FormTask {...this.props}/>)}
                <DrawerFooter>
                    {data.id && (
                    <Button 
                        style={{ marginRight: 8}} 
                        type="danger"
                        onClick={this.onDelete}>
                        <Icon type="delete" /> Remover
                    </Button>
                    )}
                    <Button 
                        style={{ marginRight: 8}} 
                        onClick={this.props.onClose}>
                        Cancelar
                    </Button>
                    {(data.id)?
                    <Button 
                        onClick={this.onSave} 
                        type="primary">Salvar
                    </Button>
                    :
                    <Button 
                        onClick={this.onCreate} 
                        type="primary">Cadastrar
                    </Button>
                    }
                </DrawerFooter>
            </Drawer>
        </div>
      );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user,
        customers: state.customers
    }
}

const mapDispatchProps = dispatch => {
    return{
        registerItem: (data) => dispatch({
            type: 'REGISTER_DASHTASK_ITEM',
            payload: {
                request:{
                    method: 'post',
                    url:'/dashtasks',
                    data: data
                }
            }
        }),
        saveItem: (id, data) => dispatch({
            type: 'UPDATE_DASHTASK_ITEM',
            payload: {
                request:{
                    method: 'put',
                    url:`/dashtasks/${id}`,
                    data: data
                }
            }
        }),
        deleteItem: (id) => dispatch({
            type: 'DELETE_DASHTASK_ITEM',
            payload: {
                request:{
                    method: 'delete',
                    url:`/dashtasks/${id}`
                },
            },
            id: id
        })
    }
}
  
export default connect(mapStateToProps, mapDispatchProps)(Form.create()(DrawerForm));