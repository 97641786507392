import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Icon, Table, Menu, Dropdown, Tooltip } from 'antd';

import { request } from '../Instance';

import './grid.css';

const { Column } = Table;

const months = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Ago','Nov','Dez'];

function getBackgroundColorCell(record, index){
    if(record.months[index]){
      if(record.months[index].revised_at != null){
        return '#52c41a';
      }else if(record.months[index].executed_at != null){
        return '#b7eb8f';
      }else if(record.months[index].status == 'Vencida'){
        return '#ff4d4f';
      }else{
        return '#ffffb8';
      }
    }else{
      return '#fafafa';
    }
}

class DrawerForm extends React.Component {

  state = {
    customers: [],
    loading: true
  }

  loadTaskGrid = (task_id, year) => {
    request('get', `/calendar/task/${task_id}/grid/${year}`, {}, false)
    .then(response => this.setState({
      loading: false, 
      customers: response.data
    }));
  }

  changeStatus = (record_task_id, status) => {
    request('patch', `/calendar/task_record/${record_task_id}/status/${status}`)
    .then(response => this.setState({
      customers: [
        ...this.state.customers.map(row => {
          if(row.customer_id != response.data.data.customer_id){
            return row;
          }else{
  
            let obj = {};
            Object.keys(row.months).map(index => {
              if(row.months[index].id != record_task_id){
                obj[index] = row.months[index];
              }else{
                obj[index] = response.data.data;
              }
            });

            return {
              ...row,
              months: obj
            }
          }
        })
      ]
    }));
  }

  changeStatusAll = (status, month) => {
    const d = this.props.date.split("-");
    const year = d[1];

    request('patch', `/calendar/task/${this.props.data.id}/status`,{
      status,
      month,
      year
    })
    .then(response => {
      this.setState({loading: true});
      this.loadTaskGrid(this.props.data.id, year);
    });
  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.data && nextProps.data != this.props.data){
      const d = nextProps.date.split("-");
      this.loadTaskGrid(nextProps.data.id, d[1]);
    }
  }

  render() {
    const { data } = this.props;
    const { loading, customers } = this.state;

    return (
      <div>
          {data && (
            <Drawer
              title={data.name}
              width={1200}
              placement="right"
              onClose={() => {
                this.props.onClose();
                this.props.loadCalendarTasks(this.props.match.params.id, this.props.date);
                this.setState({loading: true, customers: []});
              }}
              maskClosable={false}
              visible={this.props.visible}
              style={{
                  height: 'calc(100% - 55px)',
                  overflow: 'auto',
                  paddingBottom: 53,
              }}
            >
              <div className="table-clabs">
                <Table 
                  rowKey="customer_id"
                  loading={loading}
                  dataSource={customers} 
                  pagination={false}
                  bordered={true}
                  rowClassName="row-clabs-grid"
                  size="small"
                  scroll={{ x: `1350px`, y: `${window.innerHeight-175}px`}}
                >
                    <Column
                      title="Cod"
                      dataIndex="cod"
                      key="cod"
                      fixed="left"
                      width={90}
                      render={(text, record) => this.props.getCustomer(record.customer_id).cod}
                    />
                    <Column
                      title="Cliente"
                      dataIndex="customer"
                      key="customer"
                      width={300}
                      fixed="left"
                      render={(text, record) => this.props.getCustomer(record.customer_id).name}
                    />
                    {months.map((month, monthIndex) => {

                      monthIndex = monthIndex+1;

                      return (
                      <Column
                          title={
                            <Dropdown overlay={
                              <Menu onClick={(e) => this.changeStatusAll(e.key, monthIndex)}>
                                <Menu.Item key="priority">
                                  <Icon type="star" theme="twoTone" twoToneColor="#fadb14"/> Marcar todos como prioridade
                                </Menu.Item>
                                <Menu.Item key="uncheck_priority">
                                  <Icon type="star"/> Remover prioridade de todos
                                </Menu.Item>
                              </Menu>
                            } trigger={['click']}>
                            <a className="ant-dropdown-link" href="#">
                              {month} <Icon type="down" />
                            </a>
                          </Dropdown>
                          }
                          dataIndex={monthIndex}
                          key={monthIndex}
                          width={80}
                          className="grid-month"
                          render={(text, record) => {
                            const record_task = record.months[monthIndex];
                            if(record_task == undefined){
                              return false;
                            }
                            return(
                            <Dropdown overlay={
                              <Menu onClick={(e) => this.changeStatus(record.months[monthIndex].id, e.key)}>
                                {!record_task.executed_at && (<Menu.Item key="executed"><Icon type="check"/> Marcar como executado</Menu.Item>)}
                                {record_task.executed_at !=null && !record_task.revised_at && (<Menu.Item key="uncheck_executed"><Icon type="close"/> Desmarcar como executado</Menu.Item>)}
                                {!record_task.revised_at && (<Menu.Item key="revised"><Icon type="check" />Marcar como revisado</Menu.Item>)}
                                {record_task.revised_at !=null && (<Menu.Item key="uncheck_revised"><Icon type="close"/> Desmarcar como revisado</Menu.Item>)}
                                <Menu.Divider />
                                {record_task.priority == 0 && (<Menu.Item key="priority"><Icon type="star" theme="twoTone" twoToneColor="#fadb14"/> Marcar como prioridade</Menu.Item>)}
                                {record_task.priority == 1 && (<Menu.Item key="uncheck_priority"><Icon type="star"/> Desmarcar como prioridade</Menu.Item>)}
                              </Menu>
                            } trigger={['contextMenu','click']}>
                              <div style={{ 
                                userSelect: 'none',
                                backgroundColor: getBackgroundColorCell(record, monthIndex),
                                height: `100%`,
                                width: `100%`,
                                position: `absolute`,
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                              }}>
                              {record_task.priority == 1 && (<Icon type="star" theme="twoTone" twoToneColor="#fadb14"/>)}
                              </div>
                            </Dropdown>
                        )}
                        }
                      />
                      )}
                    )}
                </Table>
              </div>
            </Drawer>
        )}
      </div>
    );
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadCalendarTasks: (id, date) => dispatch({
      type: 'LOAD_CALENDAR_TASKS_MONTH',
      payload: {
        request:{
          method: 'get',
          url:`/calendar/tasks/department/${id}/${date}`
        }
      }
    })
  }
}

const mapStateToProps = state => {
  return{
    getCustomer: (id) => state.customers.filter(item => item.id==id)[0]
  }
}

export default connect(mapStateToProps, mapDispatchProps)(DrawerForm);