import React, { Component } from 'react';

import Layout from '../components/UI/Layout/Layout';
import CustomersList from '../components/Customers/CustomersList';
import CustomersForm from '../components/Customers/CustomersForm';
import CustomersDetails from '../components/Customers/CustomersDetails';

class ScreensCustomers extends Component {

    state = {
        visible: false,
        details: null,
        edit: null
    };

    showModal = () => {
        this.setState({ visible: true });
    }

    handleCancel = () => {
        this.setState({ visible: false, edit: null });
    }

    render() {
        return (
            <div>
                <Layout>
                    <CustomersList
                        showModal={this.showModal}
                        showDetails={(customer) => this.setState({details: customer})}
                        showEdit={(customer) => this.setState({edit: customer})}
                    />
                    <CustomersForm 
                        visible={this.state.visible || this.state.edit}
                        onClose={this.handleCancel}
                        data={this.state.edit}
                    />
                    <CustomersDetails 
                        visible={this.state.details}
                        customer={this.state.details}
                        onClose={() => this.setState({details: null})}

                    />
                </Layout>
            </div>
        );
    }
}

export default ScreensCustomers;