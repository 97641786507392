import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Icon, Dropdown, Menu, Modal, Button, Input } from 'antd';
import Moment from 'react-moment';

const { Column } = Table;
const Search = Input.Search;

const getColorTag = (status) => {
    switch(status){
        case 'Expirado': return '#f5222d';
        case 'Expirando': return '#fadb14';
        case 'Ativo': return '#52c41a';
    } 
}

class CertificatesList extends Component {

    state = {
        view: null,
        search: ''
    }

  // componentDidMount = () => this.props.loadCertificates();

  onAction = (e, certificate) => {
    switch(e.key){
      case 'edit': 
      this.props.showEdit(certificate);
      break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este certificado?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteCertificate(certificate.id)
        });
      break;
    }
  }

  render() {
    const { certificates } = this.props;
    const { view } = this.state;
    const { search } = this.state;

    let filteredCertificates = (certificates !== null)? certificates.filter((str) => {

      if(str.owner_name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      return false;
    }): [];

    return (  
      <div>
        <div style={{ 
            margin: `0px -16px`, 
            padding: '15px', 
            background: '#fff', 
            marginBottom: `10px` }}>
            <h1 style={{fontSize: `20px`}}>Certificados{' '}
              <Search 
                placeholder="Pesquisar certificados" 
                onChange={e => this.setState({search: e.target.value})}
                style={{width: `30%`}}
              />
              <Button type="primary" style={{float: `right`}} onClick={this.props.showModal}>
                  <Icon type="plus" /> Cadastrar Certificado
              </Button>
            </h1>
        </div>
        <div className="table-clabs">
          <Table 
            rowKey="key"
            dataSource={filteredCertificates} 
            pagination={{ pageSize: 10 }}
            bordered={true}
            rowClassName="row-clabs"
            scroll={{ x: 1450, y: `${window.innerHeight-175}px`}}
          >
              <Column
                  title="Nome"
                  dataIndex="owner_name"
                  key="owner_name"
                  fixed="left"
                  width={250}
              />
              <Column
                  title="Telefone"
                  dataIndex="phone_formated"
                  key="phone_formated"
                  width={150}
              />
              <Column
                  title="Emissão"
                  dataIndex="emission_date"
                  key="emission_date"
                  render={emission_date => <Moment format="DD/MM/YYYY">{emission_date}</Moment>}
                  width={150}
              />
              <Column
                  title="Expiração"
                  dataIndex="due_date"
                  key="due_date"
                  render={due_date => <Moment format="DD/MM/YYYY">{due_date}</Moment>}
                  width={150}
              />
              <Column
                  title="Tipo de certificado"
                  dataIndex="type"
                  key="type"
                  width={200}
              />
              <Column
                  title="Natureza"
                  dataIndex="nature"
                  key="nature"
                  width={120}
              />
              <Column
                  title="Senha"
                  dataIndex="password"
                  key="password"
                  width={180}
                  render={(text, record) => {
                      if(record.password =='' || record.password == null) return '';

                      if(record.id != view){
                          return(
                              <center><Button type="dashed" size="small" onClick={() => this.setState({view: record.id})}> visualizar <Icon type="eye" /></Button></center>
                          )
                      }else{
                          return(
                              <center><Button type="dashed" size="small" onClick={() => this.setState({view: null})}> {record.password} <Icon type="eye" /></Button></center>
                          )
                      }
                  }}
              />
              <Column
                  title="Status"
                  dataIndex="status"
                  key="status"
                  width={150}
                  render={status => <center><Tag color={getColorTag(status)} key={status}>{status}</Tag></center>}
              />
              <Column
                  dataIndex="actions"
                  key="actions"
                  fixed="right"
                  width={100}
                  render={(text, record, index) => (
                  <center key={index}>
                      <Dropdown overlay={
                          <Menu onClick={(e) => this.onAction(e, record)}>
                          <Menu.Item key="edit">
                              <Icon type="edit"></Icon> Editar
                          </Menu.Item>
                          <Menu.Divider />
                          <Menu.Item key="delete"><Icon type="delete"></Icon> Excluir</Menu.Item>
                          </Menu>
                      } trigger={['click']}>
                          <a className="ant-dropdown-link" href="#">
                          Ações <Icon type="down" />
                          </a>
                      </Dropdown>
                  </center>
              )}
              />
          </Table>
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    certificates: state.certificates
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadCertificates: () => dispatch({
      type: 'LOAD_CERTIFICATES',
      payload: {
        request:{
          method: 'get',
          url:'/certificates'
        }
      }
    }),
    deleteCertificate: (id) => dispatch({
      type: 'DELETE_CERTIFICATE',
      payload: {
        request:{
          method: 'delete',
          url:'/certificates/'+id,
        }
      },
      id: id
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(CertificatesList);