import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Icon, Dropdown, Menu, Modal, Input, Button } from 'antd';
import Moment from 'react-moment';

import { department, frequency } from './Utils';

const { Column } = Table;
const Search = Input.Search;

class List extends Component {
  state = {
    search: ''
  }

  componentDidMount = () => this.props.loadTasks();

  onAction = (e, task) => {
    switch(e.key){
      case 'customers': 
      this.props.openCustomers(task);
      break;
      case 'edit': 
      this.props.showModal(task);
      break;
      // case 'disable': 
      //   Modal.confirm({
      //     title: 'Você realmente deseja desabilitar esta tarefa?',
      //     content: 'Tarefas desabilitadas não aparecem no calendário de tarefas. Esta ação poderá ser desfeita.',
      //     okText: 'Sim',
      //     okType: 'danger',
      //     cancelText: 'Cancelar',
      //     // onOk: () => this.props.deleteTask(task.id)
      //   });
      // break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir esta tarefa?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteTask(task.id)
        });
      break;
    }
  }

  render() {
    const { tasks } = this.props;

    const { search } = this.state;

    let filteredTasks = (tasks !== null)? tasks.filter((str) => {

      if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      return false;
    }): [];

      return (  
      <div>
         <div style={{ 
            margin: `0px -16px`, 
            padding: '15px', 
            background: '#fff', 
            marginBottom: `10px` }}>
            <h1 style={{fontSize: `20px`}}>Tarefas{' '}
            <Search 
              placeholder="Pesquisar tarefas" 
              onChange={e => this.setState({search: e.target.value})}
              style={{width: `30%`}}
            />
            <Button type="primary" style={{float: `right`}} onClick={this.props.showModal}>
                <Icon type="folder-add"/> Nova tarefa
            </Button>
            </h1>
        </div>
        <div className="table-clabs">
          <Table 
            rowKey="key"
            dataSource={filteredTasks} 
            pagination={{ pageSize: 10 }}
            bordered={true}
            rowClassName="row-clabs"
            locale={{
              filterConfirm: 'Ok',
              filterReset: 'Resetar' ,
              emptyText: 'Nenhum registro corresponde ao filtro'
            }}
          >
            <Column
              title="Tarefa"
              dataIndex="name"
              key="name"
              sorter={(a, b) => a.name.localeCompare(b.name)}
              sortDirections={['descend', 'ascend']}
              defaultSortOrder={'ascend'}
            />
            <Column
              title="Departamento"
              dataIndex="department"
              key="department"
              filters={[{
                  text: department(1),
                  value: '1',
                }, {
                  text: department(2),
                  value: '2',
                },
                {
                  text: department(3),
                  value: '3',
                },
                {
                  text: department(4),
                  value: '4',
                },
                {
                  text: department(5),
                  value: '5',
                }
              ]}
              filterMultiple={false}
              onFilter={(value, record) => record.department.indexOf(value) === 0}
              render={key => (
                department(key)
              )}
            />
            <Column
              title="Periodicidade"
              dataIndex="frequency"
              key="frequency"
              render={key => (
                frequency(key)
              )}
            />
            <Column
              title="Cadastro"
              dataIndex="updated_at"
              key="updated_at"
              render={key => (
                <Moment format="DD/MM/YYYY">
                  {key}
                </Moment>
            )}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              render={status => (
                  <center><Tag color="green" key={status}>{status}</Tag></center>
              )}
            />
          <Column
              dataIndex="actions"
              key="actions"
              render={(text, record, index) => (
              <center key={index}>
                  <Dropdown overlay={
                      <Menu onClick={(e) => this.onAction(e, record)}>
                      <Menu.Item key="customers">
                          <Icon type="team"></Icon> Clientes
                      </Menu.Item>
                      <Menu.Item key="edit"><Icon type="edit"></Icon> Editar</Menu.Item>
                      <Menu.Divider />
                      <Menu.Item key="delete"><Icon type="delete"></Icon> Excluir</Menu.Item>
                      </Menu>
                  } trigger={['click']}>
                      <a className="ant-dropdown-link" href="#">
                      Ações <Icon type="down" />
                      </a>
                  </Dropdown>
              </center>
            )}
            />
          </Table>
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    tasks: state.tasks
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadTasks: (data) => dispatch({
      type: 'LOAD_TASKS',
      payload: {
        request:{
          method: 'get',
          url:'/tasks',
          data: data
        }
      }
    }),
    deleteTask: (id) => dispatch({
      type: 'DELETE_TASK',
      payload: {
        request:{
          method: 'delete',
          url:`/task/${id}`,
        }
      },
      id: id
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(List);