import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Switch, Table, Icon, Tooltip, Button, Badge } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';

import CustomFields from './CustomFields';

const { Column } = Table;

const getBadge = (status) => {
    switch(status){
        case 'Executada': return 'success';
        case 'Revisada': return 'success';
        case 'Vencida': return 'error';
        default: return 'warning';
    }
}

class CalendarTaskList extends React.Component {

    state = {
        recordSelected: null
    }

  render() {
    const { data } = this.props;
    const { recordSelected } = this.state;

    return (
      <div>
          {data && (
            <Drawer
                title={data.name}
                width={1000}
                placement="right"
                onClose={this.props.onClose}
                maskClosable={true}
                visible={this.props.visible}
                className="drwawer-padding-0"
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    padding: '0px',
                }}
            >

            <Table 
                rowKey="id"
                dataSource={data.records} 
                pagination={false}
                rowClassName="row-clabs"
                size="middle" 
                locale={{
                    filterConfirm: 'Ok',
                    filterReset: 'Resetar' ,
                    emptyText: 'Nenhum registro corresponde ao filtro'
                }}
                scroll={{ x: `${780+(data.custom_fields? Object.keys(data.custom_fields).length*200:0)}px`, y: `${window.innerHeight-175}px`}}
            >
                <Column
                    title=""
                    key="status"
                    width={50}
                    filters={[{
                        text: 'Pendente',
                        value: 'Pendente',
                      }, {
                        text: 'Executada',
                        value: 'Executada',
                      },
                      {
                        text: 'Revisada',
                        value: 'Revisada',
                      }
                    ]}
                    filterMultiple={false}
                    onFilter={(value, record) => record.status.indexOf(value) === 0}
                    render={(text, record) => (
                        <Tooltip placement="bottom" title={record.status}>
                            <Badge status={getBadge(record.status)}/>
                        </Tooltip>
                    )}
                />
                <Column
                    title=""
                    dataIndex="priority"
                    key="priority"
                    width={50}
                    render={(text, record) => (
                        record.priority == 1 && (
                        <Tooltip placement="bottom" title="Prioridade">
                            <Icon type="star" theme="filled" style={{color: `#fadb14`}}></Icon>
                        </Tooltip>
                        )
                    )}
                />
                <Column
                    title="Executado"
                    dataIndex="executed"
                    key="executed"
                    width={100}
                    render={(text, record) => (
                    <Tooltip placement="bottom" title={record.executed_at !==null? `Executado por ${this.props.getUser(record.executed_by).name} - ${moment(record.executed_at).fromNow()}`: 'Pendente'}>
                            <Switch 
                                defaultChecked={record.executed_at !== null}
                                onChange={(status) => this.props.updateStatus(record.id, status?'executed':'uncheck_executed')}
                            />
                        </Tooltip>
                    )}
                />
                <Column
                    title="Revisado"
                    dataIndex="revised"
                    key="revised"
                    width={100}
                    render={(text, record) => (
                        <Tooltip placement="bottom" title={record.revised_at !==null? `Revisado por ${this.props.getUser(record.revised_by).name} - ${moment(record.revised_at).fromNow()}`: 'Pendente'}>
                            <Switch 
                                defaultChecked={record.revised_at != null}
                                onChange={(status) => this.props.updateStatus(record.id, status?'revised':'uncheck_revised')}
                                disabled={record.executed_at == null}
                            />
                        </Tooltip>
                    )}
                />
                <Column
                    title="Cod"
                    dataIndex="customer_id"
                    key="cod"
                    render={(text, record) => (<span>{record.customer.cod}</span>)}
                    width={80}
                    sorter={(a, b) => a.customer.cod - b.customer.cod}
                    sortDirections={['descend', 'ascend']}
                />
                <Column
                    title="Cliente"
                    dataIndex="customer_id"
                    key="customer_id"
                    render={(text, record) => (<span>{record.customer.name}</span>)}
                    width={250}
                    sorter={(a, b) => a.customer.name.localeCompare(b.customer.name)}
                    sortDirections={['descend', 'ascend']}
                    defaultSortOrder={'ascend'}
                />
                <Column
                    title="Tributação"
                    dataIndex="type_taxation"
                    key="type_taxation"
                    width={200}
                    render={(text, record) => (<span>{record.customer.type_taxation}</span>)}
                />
                {data.custom_fields && Object.keys(data.custom_fields).map(key => 
                <Column
                    title={data.custom_fields[key][0]}
                    dataIndex={key}
                    key={key}
                    width={200}
                    render={ (text, record) => 
                        <Button 
                            type="dashed" 
                            size="small"
                            onClick={() => this.setState({recordSelected: record})}>
                            {(record.custom_fields != null && record.custom_fields.hasOwnProperty(key) && record.custom_fields[key] != '' ? record.custom_fields[key] : 'Preencher')}
                        </Button>
                        
                    }
                />
                )}
            
            </Table>
            
            <CustomFields
                onClose={() => this.setState({recordSelected: null})}
                data={recordSelected}
                task={data}
            />
            
        </Drawer>
        )}
      </div>
    );
  }
}

const mapDispatchProps = dispatch => {
  return{
    updateStatus: (record_task_id, status) => dispatch({
      type: 'CALENDAR_TASK_RECORD_STATUS',
      payload: {
        request:{
          method: 'patch',
          url: `/calendar/task_record/${record_task_id}/status/${status}`,
        }
      }
    })
  }
}

const mapStateToProps = (state, ownProps) => {
    return{
        getCustomer: (id) => state.customers.filter(item => item.id==id)[0],
        getUser: (id) => state.users.filter(item => item.id==id)[0],
        data: state.calendarTasks.filter(row => row.id == ownProps.id)[0],
    }
}

export default connect(mapStateToProps, mapDispatchProps)(CalendarTaskList);