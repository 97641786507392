import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Statistic, Card, Icon, Progress } from 'antd';

import { request } from '../components/Instance';

class Dashboard extends Component {

    state = {
        taskmonth: null
    }

    componentDidMount = () => {
        request('get', `/dashboard`, {}, false)
        .then(response => {
          this.setState({ taskmonth: response.data });
        });
    }

    render() {
        const { customers, certificates } = this.props;
        const { taskmonth } = this.state;
        
        return (
            <Row gutter={16}>
            <Col span={8}>
                <Card style={{marginBottom: `10px`}} loading={(customers==null)}>
                    {customers !== null && (
                    <Statistic 
                        title="Clientes Ativos" 
                        value={customers.length} 
                        prefix={<Icon type="team" />}
                        loading={true}
                    />
                    )}
                </Card>
                <Card style={{marginBottom: `10px`}} loading={(certificates==null)}>
                    {certificates !== null && (
                    <Statistic 
                        title="Certificados Digitais" 
                        value={certificates.length} 
                        prefix={<Icon type="cloud" />}
                    />
                    )}
                </Card>
            </Col>
            <Col span={16}>
                <Card title="Tarefas do mês | Resumo" loading={(taskmonth == null)}>
                    {taskmonth !== null && (
                    <div>
                        <Row gutter={16} className="mb-10">
                            <Col span={8}>
                                <h2 className="h2">Legalização</h2>
                            </Col>
                            <Col span={16}>
                                <Progress percent={taskmonth[1]} />
                            </Col>
                        </Row>
                        <Row gutter={16} className="mb-10">
                            <Col span={8}>
                                <h2 className="h2">Administrativo</h2>
                            </Col>
                            <Col span={16} className="mb-10">
                                <Progress percent={taskmonth[2]} />
                            </Col>
                        </Row>
                        <Row gutter={16} className="mb-10">
                            <Col span={8}>
                                <h2 className="h2">Contábil</h2>
                            </Col>
                            <Col span={16}>
                                <Progress percent={taskmonth[3]} />
                            </Col>
                        </Row>
                        <Row gutter={16} className="mb-10">
                            <Col span={8}>
                                <h2 className="h2">Fiscal</h2>
                            </Col>
                            <Col span={16}>
                                <Progress percent={taskmonth[4]} />
                            </Col>
                        </Row>
                        <Row gutter={16} className="mb-10">
                            <Col span={8}>
                                <h2 className="h2">Pessoal</h2>
                            </Col>
                            <Col span={16}>
                                <Progress percent={taskmonth[5]} />
                            </Col>
                        </Row>
                    </div>
                    )}
                </Card>
            </Col>
        </Row>
        )
  }
}

const mapStateToProps = state => {
    return{
      customers: state.customers,
      certificates: state.certificates
    }
}

export default connect(mapStateToProps)(Dashboard);