import React, { Component } from 'react';

import Layout from '../components/UI/Layout/Layout';
import List from '../components/RegisterTasks/List';
import Form from '../components/RegisterTasks/Form';
import Customers from '../components/RegisterTasks/Customers';

class ScreensRegisterTasks extends Component {
    state = {
        visible: null,
        task: null
    };

    showModal = (data = null) => {
        this.setState({ visible: data });
    }

    handleCancel = () => {
        this.setState({ visible: null });
    }

    render() {
        return (
            <Layout>
                <List
                    openCustomers={(task) => this.setState({task: task})} 
                    showModal={this.showModal}
                />
                <Form 
                  visible={(this.state.visible !== null)}
                  onClose={this.handleCancel}
                  data={this.state.visible}
                />
                <Customers 
                  visible={this.state.task != null}
                  task={this.state.task}
                  onClose={() => this.setState({task: null})}
                />
            </Layout>
        );
    }
}

export default ScreensRegisterTasks;