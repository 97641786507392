const calendarTasks = (state = [], action) => {
    switch (action.type) {
        case 'LOAD_CALENDAR_TASKS_MONTH_SUCCESS':
        return action.payload.data;

        case 'CALENDAR_TASK_RECORD_STATUS_SUCCESS':
        return [
            ...state.map((row) => {
                if(row.id != action.payload.data.data.task_id){
                    return row;
                }else{
                    return {
                        ...row,
                        records: row.records.map(r => {
                            if(r.id != action.payload.data.data.id){
                                return r;
                            }else{
                                return action.payload.data.data;
                            }
                        })
                    }
                }
            })
        ];

        case 'CALENDAR_TASK_SAVE_FIELDS_SUCCESS':
        return [
            ...state.map((row) => {
                if(row.id != action.payload.data.data.task_id){
                    return row;
                }else{
                    return {
                        ...row,
                        records: row.records.map(r => {
                            if(r.id != action.payload.data.data.id){
                                return r;
                            }else{
                                return action.payload.data.data;
                            }
                        })
                    }
                }
            })
        ];

        default:
        return state
    }
}

export default calendarTasks;