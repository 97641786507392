const certificates = (state = null, action) => {
    switch (action.type) {
        case 'LOAD_CERTIFICATES_SUCCESS':
        return action.payload.data;

        case 'REGISTER_CERTIFICATE_SUCCESS':
        return [
            ...state,
            {...action.payload.data.data}
        ];

        case 'DELETE_CERTIFICATE_SUCCESS':
        return [
            ...state.filter(item => item.id != action.meta.previousAction.id),
        ];

        case 'SAVE_CERTIFICATE_SUCCESS':
        return [
            ...state.map(item => item.id != action.payload.data.data.id? item : action.payload.data.data),
        ];
        
        default:
        return state
    }
}

export default certificates;