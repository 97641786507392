import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Checkbox, Row } from 'antd';

const FormItem = Form.Item;

class UsersModalForm extends React.Component {
    state = {
        email: {}
    };

    onCreate = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.registerUser(values)
            .then(response => {
                form.resetFields();
                context.props.onCancel();
            })
            .catch(data => {
                if(data.error.response.status == 422 && data.error.response.data.email !== null){
                    context.setState({
                        email: {
                            validateStatus: 'error',
                            help: data.error.response.data.email[0]
                        }
                    });
                }
            });
        });
        
    }

    render() {
        const { visible, onCancel, form } = this.props;
        const { getFieldDecorator } = form;
        const { email } = this.state;

        return (
            <Modal
                visible={visible}
                title="Convidar novo usuário"
                okText="Convidar"
                onCancel={onCancel}
                onOk={this.onCreate}>
                <Form layout="vertical">
                    <FormItem label="Email" {...email}>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Por favor informe o email!' }],
                    })(
                        <Input onChange={() => this.setState({email:{}})}/>
                    )}
                    </FormItem>
                    <FormItem label="Permissões" className="collection-create-form_last-form-item">
                    {getFieldDecorator('permissions', {
                        rules: [{ required: true, message: 'O usuário precisa ter pelo menos uma permissão!' }],
                    })(
                        <Checkbox.Group>
                            <Row><Checkbox value="Dashboard">Dashboard</Checkbox></Row>
                            <Row><Checkbox value="Usuários">Usuários</Checkbox></Row>
                            <Row><Checkbox value="Clientes">Clientes</Checkbox></Row>
                            <Row><Checkbox value="Cadastro de Tarefas">Cadastro de Tarefas</Checkbox></Row>
                            <Row><Checkbox value="Tarefas">Tarefas</Checkbox></Row>
                            <Row><Checkbox value="Abertura e Encerramento">Auditoria</Checkbox></Row>
                            <Row><Checkbox value="Abertura e Encerramento (Criar contas a pagar)">Auditoria (Criar contas a pagar)</Checkbox></Row>
                            <Row><Checkbox value="Certificados">Certificados</Checkbox></Row>
                        </Checkbox.Group>
                    )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchProps = dispatch => {
    return{
      registerUser: (data) => dispatch({
        type: 'REGISTER_USER',
        payload: {
          request:{
            method: 'post',
            url:'/user',
            data: data
          }
        }
      })
    }
  }
  
  export default connect(null, mapDispatchProps)(Form.create()(UsersModalForm));