import React, { Component } from 'react';
import { Form, Col, Row, Input } from 'antd';

import InputMask from '../../../UI/InputMask';

class Address extends Component {
    state = {
        default: {
            postal_code: null,
            street: null,
            street_number: null,
            neighborhood: null,
            complement: null,
            city: null,
            state: null
        }
    };

  render() {
    const { getFieldDecorator } = this.props.form;

    const data = {
        ...this.state.default,
        ...this.props.data
    }

    return (
      <Row gutter={16}>
        <Col span={6}>
            <Form.Item label="CEP">
                {getFieldDecorator('postal_code', {initialValue: data.postal_code})(
                    <InputMask mask="99999-999" placeholder="CEP da empresa" />
                )}
            </Form.Item>
        </Col>
        <Col span={14}>
            <Form.Item label="Rua">
                {getFieldDecorator('street', {initialValue: data.street})(
                    <Input placeholder="Rua..." />
                )}
            </Form.Item>
        </Col>
        <Col span={4}>
            <Form.Item label="Nº">
                {getFieldDecorator('street_number', {initialValue: data.street_number})(
                    <Input placeholder="Número do estabelecimento" />
                )}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Bairro">
                {getFieldDecorator('neighborhood', {initialValue:data.neighborhood})(
                    <Input placeholder="Bairro" />
                )}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Complemento">
                {getFieldDecorator('complement', {initialValue: data.complement})(
                    <Input placeholder="Complemento" />
                )}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Cidade">
                {getFieldDecorator('city', {initialValue: data.city})(
                    <Input placeholder="Cidade" />
                )}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Estado">
                {getFieldDecorator('state', {initialValue: data.state})(
                    <Input placeholder="Estado" />
                )}
            </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default Address;