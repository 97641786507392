import React, { Component } from 'react';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Instance from '../../Instance';

class Auth extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: (props.user.id !== undefined)? false:true
        }
    }

    loadUser = () => {
        Instance().get(`/user`)
        .then(response => {
            this.props.setUser(response.data);
            this.props.loadCustomers();
            this.props.loadCertificates();
            this.props.loadUsers();
            this.setState({loading: false});

            if(!response.data.permissions.includes('Dashboard')){

                if(response.data.permissions.includes('Clientes')){
                    this.props.history.push('/clientes');
                }else if(response.data.permissions.includes('Cadastro de Tarefas')){
                    this.props.history.push('/tarefas');
                }else if(response.data.permissions.includes('Tarefas')){
                    this.props.history.push('/calendario/1/abertura-e-encerramento');
                }else if(response.data.permissions.includes('Abertura e Encerramento')){
                    this.props.history.push('/abertura-e-encerramento');
                }else if(response.data.permissions.includes('Certificados')){
                    this.props.history.push('/certificados');
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                this.props.history.push('/login');
            }
        })
    }

    componentDidMount = () => {
        if(localStorage.getItem('api_token') === null){
            this.props.history.push('/login');
        }else{
            this.loadUser();
        }
    }

    render() {
        const { loading } = this.state;
        return (
            <div>
                <div className={`pageloader ${(loading)? 'is-active':''}`}></div>
                {/* <div className="infraloader"></div> */}
                {this.props.children}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUser: data => {dispatch({type: 'SET_DATA_USER', payload: data})},
        loadCustomers: data => dispatch({
            type: 'LOAD_CUSTOMERS',
            payload: {
                request:{
                    method: 'get',
                    url:'/customers',
                    data: data
                }
            }
        }),
        loadUsers: (data) => dispatch({
            type: 'LOAD_USERS',
            payload: {
              request:{
                method: 'get',
                url:'/users',
                data: data
              }
            }
        }),
        loadCertificates: () => dispatch({
            type: 'LOAD_CERTIFICATES',
            payload: {
              request:{
                method: 'get',
                url:'/certificates'
              }
            }
        }),
    }
}


const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));