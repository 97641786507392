import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Badge, Tag } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import { toBRL } from '../UI/InputCurrency';

const grid = 8;

const getBackgroundItem = (category) => {
    switch(parseInt(category)){
        case 1: return '#52c41a';
        case 2: return '#faad14';
        case 3: return '#595959';
        case 4: return '#1890ff';
    }
}
const getTextItem = (category) => {
    switch(parseInt(category)){
        case 1: return 'Abertura';
        case 2: return 'Alteração';
        case 3: return 'Encerramento';
        case 4: return 'Tarefa';
    }
}

const ItemTask = styled.div`
    user-select: none;
    padding: ${grid*2}px;
    margin: 0 0 ${grid}px 0;
    border-radius: 3px;
    background: #fff;
    position: relative;

    ${props => props.isDragging && `
        box-shadow: 1.5px 1px 1px #ccc;
    `}

    &:hover {
        box-shadow: 1.5px 1px 1px #ccc;
    }
`

const ItemTaskTitle = styled.div`
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
`

const ItemTaskAmount = styled.span`
    ${props => props.checked && `
        color: #52c41a;
        font-weight: bold;
    `}
`

const ItemTaskDate = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    background-color: #fff;
`

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const getListStyle = (isDraggingOver, listStyle) => ({
    background: listStyle.background,
    padding: grid,
    width: `100%`
});

class Dashboard extends Component {

    lists = {
        not_started: { name:'Não Iniciado', style: { background: '#f5f5f5', color: '#1890ff' }},
        in_process: { name:'Em Processo',  style: { background: '#f5f5f5', color: '#faad14' }},
        done: { name:'Concluido', style: { background: '#f5f5f5', color: '#52c41a' }},
    }

    componentDidMount = () => this.props.loadDashboard();

    onDragEnd = result => {
        const { updateList, getList } = this.props;
        const { source, destination } = result;
    
        // dropped outside the list
        if (!destination) {
            return;
        }
    
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );
    
            updateList(source.droppableId, items);
        } else {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            this.props.updateTask(getList(source.droppableId)[source.index].id, destination.droppableId);
    
            updateList(source.droppableId, result[source.droppableId]);
            updateList(destination.droppableId, result[destination.droppableId]);
        }
    };

    render() {
        const { list, getCustomer, getList, showDrawer } = this.props;

        return (
            <Row gutter={16}>
                <DragDropContext 
                    onDragEnd={this.onDragEnd}>
                        {Object.keys(this.lists).map(idList =>
                        <Col span={8} key={idList}>
                            <Droppable droppableId={idList}>
                                {(provided, snapshot) => (
                                    <Card 
                                        title={this.lists[idList].name}
                                        bodyStyle={{ padding: 0 }}
                                        headStyle={this.lists[idList].style}
                                        extra={<Badge count={list[idList].length} style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}/>}
                                    >
                                    <div 
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver, this.lists[idList].style)}
                                        >
                                        {getList(idList).map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <ItemTask
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        isDragging={snapshot.isDragging}
                                                        isDraggingOver={snapshot.isDraggingOver}
                                                        item={item}
                                                        style={provided.draggableProps.style}
                                                        onClick={() => showDrawer(item.category, item)}
                                                        >
                                                        <ItemTaskTitle>{getCustomer(item.customer_id).name}</ItemTaskTitle>
                                                        <ItemTaskDate><Moment fromNow>{item.updated_at}</Moment></ItemTaskDate>
                                                        <Tag className="mt-sm" color={getBackgroundItem(item.category)}>
                                                            {getTextItem(item.category)}
                                                        </Tag>
                                                        {item.custom_fields.amount !== 0 && (
                                                        <ItemTaskAmount 
                                                            checked={(item.custom_fields.create_payments !== undefined && item.custom_fields.create_payments)}>
                                                            R$ {toBRL(item.custom_fields.amount)}
                                                        </ItemTaskAmount>
                                                        )}
                                                    </ItemTask>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                    </Card>
                                )}
                            </Droppable>
                        </Col>
                        )}
                </DragDropContext>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    list: state.dashTasks,
    getList: (list) => state.dashTasks[list].sort(function(a,b){
        return new Date(b.updated_at) - new Date(a.updated_at);
    }),
    getCustomer: (id) => {
        const get = state.customers && state.customers.filter(row => row.id == id);

        if(get && get.length){
            return get[0];
        }

        return {
            name: 'Não encontrado'
        }
    }
})

const mapDispatchProps = dispatch => ({
    updateList: (list, data) => dispatch({
        type: 'UPDATE_DASHTASK_LIST',
        payload: {
            list,
            data
        }
    }),
    loadDashboard: () => dispatch({
        type: 'LOAD_DASHTASKS',
        payload: {
          request:{
            method: 'get',
            url:'/dashtasks',
          }
        }
    }),
    updateTask: (id, list) => dispatch({
        type: 'UPDATE_DASHTASKS_TASK',
        payload: {
          request:{
            method: 'put',
            url:`/dashtasks/${id}/status/${list}`,
          }
        }
    })
})

export default connect(mapStateToProps, mapDispatchProps)(Dashboard);