import React, { Component } from 'react';
import { Form, Col, Row, Input, Select, Divider } from 'antd';

import InputMask from '../../../UI/InputMask';

const { Option } = Select;

class Default extends Component {
    state = {
        email: {},
        default: {
            cod: null,
            name: null,
            identification_number: null,
            type_taxation: null,
            email: null,
            acquisition: null,
            indication: null
        },
        activeForm: {}
    };

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.error != this.props.error){
            const { error } = nextProps;

            if(error.response && error.response.status == 422 && error.response.data.email !== null){
                this.setState({
                    email: {
                        validateStatus: 'error',
                        help: error.response.data.email[0]
                    }
                });
            }
        }
    }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { email } = this.state;
    const data = {
        ...this.state.default,
        ...this.props.data,
        ...this.state.activeForm
    }

    return (
      <Row gutter={16}>
        <Col span={4}>
            <Form.Item label="Código">
                {getFieldDecorator('cod', {initialValue: data.cod})(
                    <Input placeholder="Código interno de identificação" />
                )}
            </Form.Item>
        </Col>
        <Col span={20}>
            <Form.Item label="Nome da Empresa">
            {getFieldDecorator('name', {
                initialValue: data.name,
                rules: [{ required: true, message: 'Por favor informe o nome da empresa!' }],
            })(<Input placeholder="Insira o nome da empresa" />)}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="CNPJ">
                {getFieldDecorator('identification_number', {initialValue: data.identification_number})(
                    <InputMask mask="99.999.999/9999-99" placeholder="CNPJ" />
                )}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Tipo">
                {getFieldDecorator('type_taxation', {initialValue: data.type_taxation})(
                    <Select placeholder="Selecione um tipo de tributação">
                        <Option value="Doméstica">Doméstica</Option>
                        <Option value="Lucro Presumido">Lucro Presumido</Option>
                        <Option value="Lucro Real">Lucro Real</Option>
                        <Option value="Fiscal">Fiscal</Option>
                        <Option value="Pessoal">Pessoal</Option>
                        <Option value="MEI">MEI</Option>
                        <Option value="Profissional Liberal">Profissional Liberal</Option>
                        <Option value="Simples Nacional">Simples Nacional</Option>
                    </Select>
                )}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Email" {...email}>
                {getFieldDecorator('email', {initialValue: data.email})(
                    <Input placeholder="Email da empresa" />
                )}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Telefone">
                {getFieldDecorator('phone', {initialValue: data.phone})(
                    <InputMask 
                        mask="(99) 9999tt999?" 
                        formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} 
                        maskChar={null} 
                        placeholder="Telefone da empresa" 
                    />
                )}
            </Form.Item>
        </Col>
        <Divider orientation="left">Outros</Divider>
        <Col span={12}>
            <Form.Item label="Tipo de Aquisição">
                {getFieldDecorator('acquisition', data.acquisition!==null?{initialValue: data.acquisition}:{})(
                    <Select placeholder="Selecione uma opção" onChange={(value) => this.setState({activeForm: {...data, acquisition:value}})}>
                        <Option value="Nenhum">Nenhum</Option>
                        <Option value="Indicação">Indicação</Option>
                        <Option value="Google">Google</Option>
                        <Option value="Facebook">Facebook</Option>
                        <Option value="Site">Site</Option>
                        <Option value="Outros">Outros</Option>
                    </Select>
                )}
            </Form.Item>
        </Col>
        {(data.acquisition !== null && (data.acquisition === 'Indicação' || data.acquisition === 'Outros')) && (
        <Col span={12}>
            <Form.Item label={(data.acquisition === 'Indicação')?'Quem?': 'Qual?'}>
                {getFieldDecorator('indication', {initialValue: data.indication})(
                    <Input placeholder={(data.acquisition === 'Indicação')?'Descreva quem indicou': 'Descreva qual tipo de aquisição'} />
                )}
            </Form.Item>
        </Col>
        )}
      </Row>
    )
  }
}

export default Default;