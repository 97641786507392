import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Menu, Icon, Dropdown, Avatar } from 'antd';
import Auth from './Auth';

import logo from './images/icon.png';
import logo2 from './images/contlabs_white.png';

import './layout.css';

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

class Main extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    const { user } = this.props;
    const { collapsed } = this.state;

    return (
      <Auth>
        {user.permissions && (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            trigger={null}
            width={250}
            style={{ 
              overflow: 'auto', 
              height: '100vh', 
              position: 'fixed', 
              left: 0,
              zIndex: '990'
            }}
          >
              <center style={{backgroundColor: '#092138'}}>
                  {collapsed? <img src={logo} className="logo" alt="ContLabs" style={{height: '30px', margin: '16px'}}/>:
                  <img src={logo2} className="logo" alt="ContLabs" style={{height: '52px', margin: '6px'}}/>}
              </center>
              <Menu 
                theme="dark" 
                defaultSelectedKeys={[this.props.location.pathname]} 
                mode="inline"
                onClick={({key}) => this.props.history.push(key)}
                >
                {(user.permissions.includes('Dashboard')) && (
                <Menu.Item key="/">
                  <Link to="/"><Icon type="dashboard" /> <span>Dashboard</span></Link>
                </Menu.Item>
                )}
                {(user.permissions.includes('Clientes')) && (
                <Menu.Item key="/clientes">
                  <Link to="/clientes"><Icon type="team" /> <span>Clientes</span></Link>
                </Menu.Item>
                )}
                {(user.permissions.includes('Cadastro de Tarefas')) && (
                <Menu.Item key="/tarefas">
                  <Link to="/tarefas"><Icon type="folder-add" /> <span>Cadastro de tarefas</span></Link>
                </Menu.Item>
                )}
                {(user.permissions.includes('Tarefas')) && (
                  <SubMenu
                      key="sub2"
                      title={<span><Icon type="schedule" /><span>Tarefas do mês</span></span>}
                  >
                      <Menu.Item key="/calendario/1/legalizacao">Legalização</Menu.Item>
                      <Menu.Item key="/calendario/2/administrativo">Administrativo</Menu.Item>
                      <Menu.Item key="/calendario/3/contabil">Contábil</Menu.Item>
                      <Menu.Item key="/calendario/4/fiscal">Fiscal</Menu.Item>
                      <Menu.Item key="/calendario/5/pessoal">Pessoal</Menu.Item>
                  </SubMenu>
                )}
                {(user.permissions.includes('Abertura e Encerramento')) && (
                <Menu.Item key="/auditoria">
                  <Link to="/auditoria"><Icon type="file-sync" /> <span>Auditoria</span></Link>
                </Menu.Item>
                )}
                {(user.permissions.includes('Certificados')) && (
                  <Menu.Item key="/certificados">
                    <Link to="/certificados"><Icon type="cloud" /> <span>Certificados Digitais</span></Link>
                  </Menu.Item>
                )}
              </Menu>
          </Sider>
          <Layout style={{marginLeft: `${collapsed? '80px': '250px'}`}}>
            <Header id="header-main" style={{
              background: '#fff', 
              padding: 0,
              boxShadow: `0px 1px 8px 0px rgba(0,0,0,0.1)`,
              zIndex: `989`
              }}>
              <Icon
                  className="trigger"
                  type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={this.toggle}
                />
                <Dropdown  className="cl-menu-main-right" overlay={
                    <Menu>
                      <Menu.Item key="/conta">
                        <Link to="/conta"><Icon type="user" /> Minha Conta</Link>
                      </Menu.Item>
                      {(user.permissions.includes('Usuários')) && (
                      <Menu.Item key="/usuarios">
                        <Link to="/usuarios"><Icon type="team" /> Gerenciar Usuários</Link>
                      </Menu.Item>
                      )}
                      <Menu.Divider/>
                      <Menu.Item key="/sair">
                        <Link to="/sair"><Icon type="logout" /> Sair</Link>
                      </Menu.Item>
                    </Menu>
                  }>
                  <a className="ant-dropdown-link" href="#">
                  {/* <Avatar style={{ backgroundColor: '#722ed1' }} icon="user" size="small"/>  */}
                  {user.name} <Icon type="down" />
                  </a>
              </Dropdown>
            </Header>
            <Content style={{ margin: `0px 16px` }}>
              {this.props.children}
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              Contlabs ©2018
            </Footer>
          </Layout>
        </Layout>
        )}
      </Auth>
    );
  }
}

const mapDispatchToProps = (state) => ({
  user: state.user
});

export default connect(mapDispatchToProps)(withRouter(Main));