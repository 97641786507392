import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Checkbox, Row } from 'antd';

const FormItem = Form.Item;

class UsersEditPermissions extends React.Component {
    onSave = () => {
        const { form, data } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.savePermissions(data.id, values)
            .then(response => {
                form.resetFields();
                context.props.onCancel();
            })
        });
        
    }

    render() {
        const { visible, onCancel, form, data } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                visible={visible}
                title="Editar Permissões do Usuário"
                okText="Salvar"
                onCancel={onCancel}
                onOk={this.onSave}
                okType="primary">
                {data != null && (
                <Form layout="vertical">
                    <FormItem label="Usuário">
                        <span>{(data.name == '')?data.email:data.name}</span>
                    </FormItem>
                    <FormItem label="Permissões">
                    {getFieldDecorator('permissions', {
                        initialValue: data.permissions,
                        rules: [{ required: true, message: 'O usuário precisa ter pelo menos uma permissão!' }],
                    })(
                        <Checkbox.Group>
                            <Row><Checkbox value="Dashboard">Dashboard</Checkbox></Row>
                            <Row><Checkbox value="Usuários">Usuários</Checkbox></Row>
                            <Row><Checkbox value="Clientes">Clientes</Checkbox></Row>
                            <Row><Checkbox value="Cadastro de Tarefas">Cadastro de Tarefas</Checkbox></Row>
                            <Row><Checkbox value="Tarefas">Tarefas</Checkbox></Row>
                            <Row><Checkbox value="Abertura e Encerramento">Auditoria</Checkbox></Row>
                            <Row><Checkbox value="Abertura e Encerramento (Criar contas a pagar)">Auditoria (Criar contas a pagar)</Checkbox></Row>
                            <Row><Checkbox value="Certificados">Certificados</Checkbox></Row>
                        </Checkbox.Group>
                    )}
                    </FormItem>
                </Form>
                )}
            </Modal>
        );
    }
}

const mapDispatchProps = dispatch => {
    return{
      savePermissions: (id, data) => dispatch({
        type: 'SAVE_PERMISSIONS_USER',
        payload: {
          request:{
            method: 'patch',
            url:`/user/${id}/permissions`,
            data: data
          }
        },
        id: id
      })
    }
  }
  
  export default connect(null, mapDispatchProps)(Form.create()(UsersEditPermissions));