import React from 'react';

import SignUp from '../components/Login/SignUp';

const ScreensSignUp = () => (
    <div>
        <SignUp />
    </div>
);

export default ScreensSignUp;