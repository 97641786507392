import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Button } from 'antd';

import FormCustomer from './Form/FormCustomer';

class CustomersForm extends React.Component {
    state = {
        error: null
    }

    onCreate = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.registerCustomer(values)
            .then(response => {
                form.resetFields();
                context.props.onClose();
            })
            .catch(data => context.setState({error: data.error}));
        });
        
    }

    onSave = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.saveCustomer(this.props.data.id, values)
            .then(response => {
                form.resetFields();
                context.props.onClose();
            })
            .catch(data => {
                if(data.error){
                    if(data.error.response.status == 422 && data.error.response.data.email !== null){
                        context.setState({
                            email: {
                                validateStatus: 'error',
                                help: data.error.response.data.email[0]
                            }
                        });
                    }
                }
            });
        });
        
    }

    render() {
        const { error } = this.state;
        const data = {
            ...this.state.default,
            ...this.props.data
        }
        
        return (
        <div>
            <Drawer
                title={(data.id)? 'Editar Cliente': 'Cadastro de Cliente'}
                width={600}
                placement="right"
                onClose={this.props.onClose}
                maskClosable={false}
                visible={this.props.visible}
                destroyOnClose={true}
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
            <FormCustomer {...this.props} error={error}/>
            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
            >
                <Button
                style={{
                    marginRight: 8,
                }}
                onClick={this.props.onClose}
                >
                Cancelar
                </Button>
                {(data.id)?
                <Button onClick={this.onSave} type="primary">Salvar</Button>
                :
                <Button onClick={this.onCreate} type="primary">Cadastrar</Button>
                }
            </div>
            </Drawer>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchProps = dispatch => {
    return{
      registerCustomer: (data) => dispatch({
        type: 'REGISTER_CUSTOMER',
        payload: {
          request:{
            method: 'post',
            url:'/customer',
            data: data
          }
        }
      }),
      saveCustomer: (id, data) => dispatch({
        type: 'SAVE_CUSTOMER',
        payload: {
          request:{
            method: 'post',
            url:`/customer/${id}`,
            data: data
          }
        }
      })
    }
  }
  
export default connect(mapStateToProps, mapDispatchProps)(Form.create()(CustomersForm));