import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, Button, message } from 'antd';

import { request } from '../Instance';

const FormItem = Form.Item;

class SettingsCompanyForm extends Component {

  handleSubmit = (e) => {
    e.preventDefault();
    const { updateCompany } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        request('patch', '/company', values)
        .then(response => {
          updateCompany({ name: values.name });
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { company } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 4,
        },
      },
    };

    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="Nome Fantasia">
          {getFieldDecorator('name', {
             initialValue: company.name,
            rules: [{ required: true, message: 'Por favor informe o nome fantasia!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Salvar</Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return{
      company: state.user.tenant
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCompany: (data) => dispatch({type: 'UPDATE_COMPANY', payload: data})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(SettingsCompanyForm));