const defaultState = {
    not_started: [],
    in_process: [],
    done: []
}

const dashTasks = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOAD_DASHTASKS_SUCCESS':
        return {
            not_started: action.payload.data.data.filter(item => item.processed_at === null),
            in_process: action.payload.data.data.filter(item => item.processed_at !== null && item.finalized_at === null),
            done: action.payload.data.data.filter(item => item.finalized_at !== null)
        }

        case 'UPDATE_DASHTASK_LIST':
        return {
            ...state,
            [action.payload.list]: action.payload.data
        };

        case 'REGISTER_DASHTASK_ITEM_SUCCESS':
        return {
            ...state,
            not_started: [
                {...action.payload.data.data},
                ...state.not_started
            ]
        };

        case 'UPDATE_DASHTASK_ITEM_SUCCESS':
        return {
            not_started: state.not_started.map(item => {
                if(item.id !== action.payload.data.data.id){
                    return item;
                }else{
                    return action.payload.data.data;
                }
            }),
            in_process: state.in_process.map(item => {
                if(item.id !== action.payload.data.data.id){
                    return item;
                }else{
                    return action.payload.data.data;
                }
            }),
            done: state.done.map(item => {
                if(item.id !== action.payload.data.data.id){
                    return item;
                }else{
                    return action.payload.data.data;
                }
            })
        };

        case 'DELETE_DASHTASK_ITEM_SUCCESS':
        return {
            not_started: state.not_started.filter(item => item.id !== action.meta.previousAction.id),
            in_process: state.in_process.filter(item => item.id !== action.meta.previousAction.id),
            done: state.done.filter(item => item.id !== action.meta.previousAction.id),
        };

        default:
        return state
    }
}

export default dashTasks;