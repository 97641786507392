import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Icon, Tooltip, Menu, Dropdown } from 'antd';
import { frequency } from '../RegisterTasks/Utils';
import { groupBy } from '../UI/Utils';
import moment from 'moment';

const gridStyle1 = {
    width: '70%',
    padding: '8px',
    textAlign: 'left',
  };
const gridStyle2 = {
    width: '30%',
    textAlign: 'center',
    padding: '8px',
};


const Task = ({data, openGrid, openList, repeat}) => {

    const pendent = data.records.filter(item => item.executed_at == null).length;
    const due_date = data.records[0].due_date;
    const priority = data.records.filter(item => item.priority == 1);

    const background = () => {
        const from = moment(due_date, "YYYY-MM-DD");
        const to = moment();
        if(pendent == 0){
            return '#52c41a';
        }else if(to.diff(from, 'days') > 0){
            return '#f5222d';
        }else{
            return '#fadb14';
        }
    }

    return(
    <Col span={6}>
        <Card
            style={{ marginTop: 16 }}
            type="inner"
            title={<Dropdown overlay={
                <Menu>
                  <Menu.Item onClick={openList}>
                    <Icon type="bars"/> Visualizar tarefas em modo lista
                  </Menu.Item>
                  <Menu.Item onClick={() => openGrid(data)}>
                    <Icon type="table"/> Visualizar tarefas em modo tabela
                  </Menu.Item>
                </Menu>
              } trigger={['click']}>
              <a className="ant-dropdown-link" style={{color: `#fff`}} href="javascript:void(0)">
                {data.name} <Icon type="down" />
              </a>
            </Dropdown>}
            headStyle={{backgroundColor: background()}}
            extra={priority.length > 0 && (
                <Tooltip placement="bottom" title={`${priority.length} prioridades`}>
                    <Icon type="star" theme="filled"/>
                </Tooltip>
            )}
        >
            <Card.Grid style={gridStyle1}>Clientes</Card.Grid>
            <Card.Grid style={gridStyle2}>{groupBy(data.records, 'customer_id').length}</Card.Grid>
            <Card.Grid style={gridStyle1}>Tarefas Pendentes</Card.Grid>
            <Card.Grid style={gridStyle2}>{pendent}</Card.Grid>
            <Card.Grid style={gridStyle1}>Tarefas Concluidas</Card.Grid>
            <Card.Grid style={gridStyle2}>{data.records.filter(item => item.executed_at != null).length}</Card.Grid>
            {repeat && (<Card.Grid style={gridStyle1}>Periodicionade</Card.Grid>)}
            {repeat && (<Card.Grid style={gridStyle2}>{frequency(data.frequency)}</Card.Grid>)}
            {repeat && (<Card.Grid style={gridStyle1}>Vencimentos</Card.Grid>)}
            {repeat && (<Card.Grid style={gridStyle2}>
                <Tooltip placement="bottom" title={due_date}>
                    <Icon type="calendar"/>
                </Tooltip>
            </Card.Grid>
            )}
        </Card>
    </Col>
    );
};

class Tasks extends Component {

    componentDidMount = () => this.props.loadCalendarTasks(this.props.match.params.id, this.props.date);

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.date != this.props.date
            || this.props.match.params.id != nextProps.match.params.id){
            this.props.loadCalendarTasks(nextProps.match.params.id, nextProps.date);
        }
    }

    render() {
        const { tasks, tasks_no_repeat } = this.props;

        return (
            <div>
            <Row gutter={16}>
                {tasks_no_repeat.map((task, index) => 
                    <Task 
                        openList={() => this.props.openList(task.id)} 
                        openGrid={this.props.openGrid} 
                        data={task} 
                        key={index}
                        repeat={false}
                    /> 
                )}
            </Row>
            <Row gutter={16}>
                {tasks.map((task, index) => 
                    <Task 
                        openList={() => this.props.openList(task.id)} 
                        openGrid={this.props.openGrid} 
                        data={task} 
                        key={index}
                        repeat={true}
                    /> 
                )}
                {tasks.length == 0 && tasks_no_repeat.length == 0 && (
                <div className="mt-10">
                    <center>
                        <Icon type="folder-open" style={{fontSize: `42px`}}/>
                        <h1 className="h1">Nenhuma tarefa encontrada.</h1>
                    </center>
                </div>
                )}
            </Row>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        tasks_no_repeat: state.calendarTasks.filter(row => row.frequency == 0).sort((a, b) => a.name.localeCompare(b.name)),
        tasks: state.calendarTasks.filter(row => row.frequency != 0).sort((a, b) => a.name.localeCompare(b.name)),
    }
  }
  
  const mapDispatchProps = dispatch => {
    return{
      loadCalendarTasks: (id, date) => dispatch({
        type: 'LOAD_CALENDAR_TASKS_MONTH',
        payload: {
          request:{
            method: 'get',
            url:`/calendar/tasks/department/${id}/${date}`
          }
        }
      })
    }
  }
  
  export default connect(mapStateToProps, mapDispatchProps)(Tasks);