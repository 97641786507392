import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ScreensLogin from './screens/Login';
import ScreensSignUp from './screens/SignUp';
import ScreensInvited from './screens/Invited';
import ScreensDashboard from './screens/Dashboard';
import ScreensUsers from './screens/Users';
import ScreensCustomers from './screens/Customers';
import ScreensRegisterTasks from './screens/RegisterTasks';
import ScreensCalendarTask from './screens/CalendarTask';
import ScreensLogout from './screens/Logout';
import ScreensAccount from './screens/Account';
import ScreeesNoMatch from './screens/NoMatch';
import ScreensCertificates from './screens/Certificates';
import ScreensOpeningClosing from './screens/OpeningClosing';

const ScreensRoot = () => (
  <Router>
    <Switch>
      {/* Rotas públicas */}
      <Route path="/login" component={ScreensLogin} />
      <Route path="/cadastro" component={ScreensSignUp} />
      <Route path="/convidado/:invitation" component={ScreensInvited} />

      {/* Routas que necessitam de autenticação */}
      <Route path="/usuarios" component={ScreensUsers} />
      <Route path="/clientes" component={ScreensCustomers} strict/>
      <Route path="/certificados" component={ScreensCertificates} strict/>
      <Route path="/auditoria" component={ScreensOpeningClosing} strict/>
      <Route path="/conta" component={ScreensAccount} strict/>
      <Route path="/tarefas" component={ScreensRegisterTasks} strict/>
      <Route path="/calendario/:id/:department" component={ScreensCalendarTask} strict/>
      <Route path="/sair" component={ScreensLogout} />
      <Route path="/" component={ScreensDashboard} exact/>

      {/* Rota 404 */}
      <Route component={ScreeesNoMatch}/>
    </Switch>
  </Router>
);

export default ScreensRoot;