import React from 'react';
import { DatePicker as DatepickerAntd } from 'antd';
import CalendarLocale from 'rc-calendar/lib/locale/pt_BR';
import TimePickerLocale from '../TimePicker/Locale';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'Selecionar data',
    rangePlaceholder: ['Data de início', 'Data de fim'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

export const DatePicker = (props) => (
  <DatepickerAntd 
    placeholder="Selecione uma data"
    style={{ width: '100%' }}
    locale={locale}
    format={'DD/MM/YYYY'}
    getPopupContainer={trigger => trigger.parentNode}
    {...props}
  />
)

export default locale;