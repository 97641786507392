import React, { Component } from 'react';

import Layout from '../components/UI/Layout/Layout';
import Dashboard from '../components/Dashboard';

class ScreensDashboard extends Component {
    render() {
        return (
            <Layout>
               <div style={{ 
                    margin: `0px -16px`, 
                    padding: '15px', 
                    background: '#fff', 
                    marginBottom: `10px` }}>
                    <h1 style={{fontSize: `20px`}}>DASHBOARD</h1>
                </div>
                <Dashboard />
            </Layout>
        );
    }
}

export default ScreensDashboard;