import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, Button, message } from 'antd';

import { request } from '../Instance';

const FormItem = Form.Item;

class SettingsBasicForm extends Component {

  handleSubmit = (e) => {
    e.preventDefault();
    const { updateUser } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        request('patch', '/user', values)
        .then(response => {
          updateUser({ name: values.fullname });
        })
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { user } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 4,
        },
      },
    };

    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="E-mail">
          <Input value={user.email} disabled/>
        </FormItem>
        <FormItem {...formItemLayout} label="Nome Completo">
          {getFieldDecorator('fullname', {
            initialValue: user.name,
            rules: [{ required: true, message: 'Por favor informe seu nome completo!', whitespace: true }],
          })(
            <Input/>
          )}
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Salvar</Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return{
      user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (data) => dispatch({type: 'UPDATE_USER', payload: data})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(SettingsBasicForm));