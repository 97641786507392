import React from 'react';
import { Form, Col, Row, Input, Select, Divider, Switch } from 'antd';

import InputCurrency, { currency2Float } from '../../UI/InputCurrency';

const { Option } = Select;

class FormClose extends React.Component {
    state = {
        default: {
            id: null,
            customer_id: null,
            description: null,
            amount: 0.00,
            create_payments: false
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { customers, user } = this.props;

        const data = {
            ...this.state.default,
            ...this.props.data
        };

        return (
        <Form>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label="Empresa">
                        {getFieldDecorator('customer_id', data.customer_id ?{
                            initialValue: data.customer_id,
                            rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                        }: {
                            rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                        })(
                            <Select
                                showSearch
                                placeholder="Selecione uma empresa"
                                optionFilterProp="children"
                                onChange={(val) => this.setState({default: {...this.state.default, customer_id: val}})}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                            {customers.map(row => 
                                <Option value={row.id} key={row.id}>{row.name}</Option>
                            )}
                        </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Detalhes">
                    {getFieldDecorator('description', {
                        initialValue: data.description,
                        rules: [{ required: true, message: 'Descreva detalhes sobre o encerramento' }],
                    })(
                        <Input.TextArea rows={4} placeholder="Descreva detalhes sobre o encerramento" />
                    )}
                    </Form.Item>
                </Col>
            
                <Divider>Departamento Financeiro</Divider>
                <Col span={12}>
                    <Form.Item label="Honorário de Encerramento">
                        {getFieldDecorator('amount', {
                            initialValue: data.amount,
                            normalize: (value) => currency2Float(value)
                        })(
                            <InputCurrency/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Criar contas a pagar">
                        {getFieldDecorator('create_payments')(
                            <Switch 
                                defaultChecked={data.create_payments}
                                disabled={(!user.permissions.includes('Abertura e Encerramento (Criar contas a pagar)'))}/>
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        );
    }
}

export default FormClose;