import React, { Component } from 'react';
import { Form, Col, Row, InputNumber, Select, Divider, Switch } from 'antd';

import InputCurrency, { currency2Float } from '../../../UI/InputCurrency';

const { Option } = Select;

class Opening extends Component {
    state = {
        default: {
            billing: 0.00,
            nfs_input: 0,
            nfs_output: 0,
            accounting_postings: 0,
            conciliation: false,
            n_staffs: 0,
            type_staff: null,
            first_honorary: 0.00,
            honorary: 0.00,
            first_honorary_c_payment: false,
            honorary_c_payments: false,
        }
    };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { user, dashboard } = this.props;
    const data = {
        ...this.state.default,
        ...this.props.data
    }

    return (
        <div>
            <Row gutter={16} className="mb-20">
                <Divider orientation="left">Departamento Fiscal</Divider>
                <Col span={8}>
                    <Form.Item label={<span>Faturamento <small>(mensal)</small></span>}>
                        {getFieldDecorator('billing', {
                            initialValue: data.billing,
                            normalize: (value) => currency2Float(value)
                        })(
                            <InputCurrency/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={<span>NFs Entrada <small>(mensal)</small></span>}>
                        {getFieldDecorator('nfs_input', {
                            initialValue: data.nfs_input,
                        })(
                            <InputNumber style={{width: `100%`}}/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={<span>NFs Saída <small>(mensal)</small></span>}>
                        {getFieldDecorator('nfs_output', {
                            initialValue: data.nfs_output,
                        })(
                            <InputNumber style={{width: `100%`}}/>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} className="mb-20">
                <Divider orientation="left">Departamento Contábil</Divider>
                <Col span={8}>
                        <Form.Item label={<span>Lanç. Contábeis <small>(mensal)</small></span>}>
                        {getFieldDecorator('accounting_postings', {
                            initialValue: data.accounting_postings,
                        })(
                            <InputNumber style={{width: `100%`}}/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Conciliação">
                        {getFieldDecorator('conciliation')(
                            <Switch defaultChecked={data.conciliation}/>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} className="mb-20">
                <Divider orientation="left">Departamento Pessoal</Divider>
                <Col span={12}>
                    <Form.Item label="Funcionários">
                        {getFieldDecorator('n_staffs', {
                            initialValue: data.n_staffs,
                        })(
                            <InputNumber style={{width: `100%`}}/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Tipo">
                        {getFieldDecorator('type_staff', data.id!==null?{initialValue: data.type_staff}:{})(
                            <Select placeholder="Selecione um tipo de funcionário">
                                <Option value="Honorista">Honorista</Option>
                                <Option value="Mensalista">Mensalista</Option>
                                <Option value="Comissionista">Comissionista</Option>
                                <Option value="Comissionista Puro">Comissionista Puro</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Divider orientation="left">Departamento Financeiro</Divider>
            {(dashboard || data.id ) && (
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Honorário de Abertura">
                        {getFieldDecorator('first_honorary', {
                            initialValue: data.first_honorary,
                            normalize: (value) => currency2Float(value)
                        })(
                            <InputCurrency/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Criar contas a pagar">
                        {getFieldDecorator('first_honorary_c_payment')(
                            <Switch 
                                defaultChecked={data.first_honorary_c_payment}
                                disabled={(!user.permissions.includes('Abertura e Encerramento (Criar contas a pagar)'))}/>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            )}
            <Row gutter={16} className="mb-40">
                <Col span={12}>
                    <Form.Item label="Mensalidade">
                        {getFieldDecorator('honorary', {
                            initialValue: data.honorary,
                            normalize: (value) => currency2Float(value)
                        })(
                            <InputCurrency/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Criar contas a pagar">
                        {getFieldDecorator('honorary_c_payments')(
                            <Switch 
                                defaultChecked={data.honorary_c_payments}
                                disabled={(!user.permissions.includes('Abertura e Encerramento (Criar contas a pagar)'))}/>
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
  }
}

export default Opening;